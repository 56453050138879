import React from "react";
import colors from "../../../configs/colorConfig";
import BlogItem from "./components/BlogItem";
import { blogArticles } from "../../data/blogArticles";
import { useTranslation } from "react-i18next";
import style from "./blogPage.module.scss";

export const BlogPage = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div
        className={style.mainDiv}
        style={{
          backgroundColor: "white",
        }}
      >
        <div style={{ padding: "130px", width: "100%" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div style={{ maxWidth: "700px" }}>
              <h1
                className={style.title}
                style={{
                  color: colors.primary,
                  marginBottom: "30px",
                }}
              >
                {t("start:blog.helloMessage")}
              </h1>
              <p style={{ fontSize: "18px", textAlign: "center" }}>
                {t("start:blog.helloDescription")}
              </p>
            </div>
          </div>
          <h1
            className={style.title}
            style={{
              marginTop: "50px",
              color: colors.primary,
            }}
          >
            {t("start:blog.articles")}
          </h1>
          <div className={style.articlesDiv}>
            {blogArticles.map((article, index) => (
              <BlogItem key={index} article={article} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
