import Grid from "@mui/material/Grid";
import { Box, Grow } from "@mui/material";
import style from "./loginRegistrationTemplate.module.scss";

type Props = {
  children: React.ReactNode;
};
const LoginRegistrationTemplate = ({ children }: Props) => {
  return (
    <Grid className={style.mainDiv}>
      <Grow
        in={true}
        style={{ transformOrigin: "0 0 0" }}
        {...(true ? { timeout: 500 } : {})}
      >
        <Box>{children}</Box>
      </Grow>
    </Grid>
  );
};

export default LoginRegistrationTemplate;
