import { Stack } from '@mui/material'
import React from 'react'
import Toast from './Toast'

type Props = {
    timeout?: number
}
const sx = {
    dynamicNotifications: {
        position: 'fixed',
        top: (theme: any) => theme.spacing(1),
        right: (theme: any) => theme.spacing(1),
        zIndex: 1000,
        borderRadius: '1em',
    },
  }
const Notificator = ({timeout}: Props) => {
  return (
    <Stack sx={sx.dynamicNotifications} spacing={2}>
   
</Stack>
  )
}

export default Notificator