import { Box, Typography, TypographyProps, styled } from '@mui/material';
import React from 'react';

type Props = {
    codeSnippet: string
}

const ResponseTypography = styled(Typography)<TypographyProps>({
    color: "#e0e0e0",
    fontSize: "14px",
});

const CodeSnippet = ({ codeSnippet }: Props) => {
    return (
        <Box sx={{backgroundColor:"#0D0D0D", borderRadius:"10px", padding:"10px"}}>
            
            <ResponseTypography>
                 {codeSnippet} 
            </ResponseTypography>
        </Box>
    )
}

export default CodeSnippet;