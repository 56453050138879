function romanToArabic(roman: string): number {
    const romanNumerals: { [key: string]: number } = { 'I': 1, 'V': 5, 'X': 10, 'L': 50, 'C': 100, 'D': 500, 'M': 1000 };
    let result = 0;

    for (let i = 0; i < roman.length; i++) {
        const current = romanNumerals[roman[i]];
        const next = romanNumerals[roman[i + 1]];

        if (next && current < next) {
            result -= current;
        } else {
            result += current;
        }
    }

    return result;
}

export function sortTitles(a: string, b: string): number {
    const regex = /TYTUŁ (\w+)/;
    const matchA = a.match(regex);
    const matchB = b.match(regex);

    if (matchA && matchB) {
        const numeralA = matchA[1].replace(/[^IVXLC]/g, '');;
        const numeralB = matchB[1].replace(/[^IVXLC]/g, '');;
        const isNumeralA = numeralA.match(/^(I|V|X|L|C|D|M)+$/);
        const isNumeralB = numeralB.match(/^(I|V|X|L|C|D|M)+$/);

        const letterA = matchA[1].replace(/[IVXLC]/g, '');
        const letterB = matchB[1].replace(/[IVXLC]/g, '');

        if (isNumeralA && isNumeralB) {
            if(numeralA === numeralB){
                if(letterA === ""){
                    return -1;
                }
                else if(letterB === ""){
                    return 1;
                }
                else{
                    return letterA.localeCompare(letterB);
                }
            }
            else{
                return romanToArabic(numeralA) - romanToArabic(numeralB);
            }
        } else if (isNumeralA) {
            return 1;
        } else if (isNumeralB) {
            return -1;
        }
    }

    return a.localeCompare(b);
}


export function sortSections(a: string, b: string): number {
    const regex = /DZIAŁ (\w+)/;
    const matchA = a.match(regex);
    const matchB = b.match(regex);

    if (matchA && matchB) {
        const numeralA = matchA[1].replace(/[^IVXLC]/g, '');
        const numeralB = matchB[1].replace(/[^IVXLC]/g, '');
        const isNumeralA = numeralA.match(/^(I|V|X|L|C|D|M)+$/);
        const isNumeralB = numeralB.match(/^(I|V|X|L|C|D|M)+$/);

        const letterA = matchA[1].replace(/[IVXLC]/g, '');
        const letterB = matchB[1].replace(/[IVXLC]/g, '');
        

        if (isNumeralA && isNumeralB) {
            if(numeralA === numeralB){
                if(letterA === ""){
                    return -1;
                }
                else if(letterB === ""){
                    return 1;
                }
                else{
                    return letterA.localeCompare(letterB);
                }
            }
            else{
                return romanToArabic(numeralA) - romanToArabic(numeralB);
            }
        } else if (isNumeralA) {
            return 1;
        } else if (isNumeralB) {
            return -1;
        }
    }

    return a.localeCompare(b);
}




const numberMapping: { [key: string]: number } = {
    'PIERWSZA': 1,
    'DRUGA': 2,
    'TRZECIA': 3,
    'CZWARTA': 4,
    'PIĄTA': 5,
    'SZÓSTA': 6,
    // Add more mappings as needed
  };
  
  const getNumberValueForPart = (str: string): number => {
    let cleanedStr = str.replaceAll(/\n/g, '');

    const match = cleanedStr.match(/^CZĘŚĆ (\p{L}+)/u);
    if (!match || !numberMapping[match[1]]) {
      return -1;
    }
    return numberMapping[match[1]];
  };

  const getNumberValueForBook = (str: string): number => {
    let cleanedStr = str.replaceAll(/\n/g, '');
    const match = cleanedStr.match(/^KSIĘGA (\p{L}+)/u);
    if (!match || !numberMapping[match[1]]) {
      return -1;
    }
    return numberMapping[match[1]];
  }


  


  export const sortParts = (a: string, b: string): number => {  
    return getNumberValueForPart(a) - getNumberValueForPart(b);
  }

  export const sortBooks = (a: string, b: string): number => {
    return getNumberValueForBook(a) - getNumberValueForBook(b);
  }

  

