import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { Outlet } from "react-router-dom";
import Sidebar from "../../components/layout/sidebar/Sidebar";
import colors from "../../configs/colorConfig";

const DashboardLayout = () => {
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Box position="absolute" className="sidebar">
        <Sidebar />
      </Box>
      <Box marginLeft={"81px"}>
        <Box
          component="main"
          sx={{
            height: "100vh",
            width: "calc(100vw - 81px)",
            backgroundColor: colors.grayBG,
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardLayout;
