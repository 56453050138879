import { Step } from "react-joyride";

export const steps: Step[] = [
  {
    target: ".leftSidePanel",
    title: "Witamy Cię w aplikacji PrawMi!",
    content:
      "Mamy szczerą nadzieję, że pomoże Ci ona przyspieszyć pracę i uczynić ją przyjemniejszą. Oto krótkie wprowadzenie. \n\nGłówny ekran aplikacji składa się z okna czatu oraz okna zawierającego źródła.",
    placement: "center",
  },
  {
    target: ".leftSidePanel",
    content:
      "Po lewej stronie znajduje się okno czatu. W tym oknie możesz rozmawiać z botem, który odpowie na Twoje pytania.",
    placement: "right",
  },
  {
    target: ".questionDiv",
    content:
      "Pytanie należy zadać w poniższym polu. Dwa przykładowe pytania przedstawione są powyżej.",
  },
  {
    target: ".questionAnwserIcon",
    content:
      "Każda rozmowa z botem jest zapisywana na bocznym pasku. Możesz do niej wrócić w dowolnym momencie.",
  },
  {
    target: ".rightSidePanel",
    content:
      "Po prawej stronie znajduje się okno ze źródłami. Wyświetlają sie tutaj ustawy oraz orzeczenia powiązane z poruszanym tematem.",
    placement: "left",
  },
  {
    target: ".tabSearch",
    content:
      "Pomiędzy kolejnymi oknami możesz przełączać się za pomocą zakładek. Pierwsza zakładka zawiera okno wyszukiwania. Druga pokazuje źródła dopasowane do konwersacji. Kolejne zakładki zawierają otwarte ustawy i orzeczenia.",
    placement: "left",
  },
  {
    target: ".searchTextfield",
    content:
      "Aby znaleźć interesujące Cię źródło, wpisz jego nazwę w oknie wyszukiwania.",
  },
];
