import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { Outlet } from "react-router-dom";
import NavBar from "./navbar/NavBar";

const PublicLayout = () => {
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Box>
        <Box component="main">
          <NavBar />
          {/* <Box height={"66px"} width={"100vw"} sx={{ background: "red" }} /> */}
          <Box
            sx={{
              overflowY: "auto",
              overflowX: "hidden",
              height: "calc(100vh - 66px)",
              width: "100vw",
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PublicLayout;
