import { useStripe, useElements } from "@stripe/react-stripe-js";
import { Box, Button, Typography } from "@mui/material";
import { auth, firestore } from "../../../../configs/firebaseConfig";
import { addDoc, collection, doc, onSnapshot } from "firebase/firestore";

const SubscribePanel = () => {
  async function createCheckoutSession(uid: string) {
    const docRef = await addDoc(
      collection(firestore, "Users", `${uid}`, "checkout_sessions"),
      {
        price: "price_1OfecgIUxHAJi56N27PWrJ2I",
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      }
    );

    onSnapshot(
      doc(firestore, "Users", `${uid}`, "checkout_sessions", docRef.id),
      (doc) => {
        if (doc.data()?.url) {
          window.open(doc.data()?.url);
        }
      }
    );
  }

  const stripe = useStripe();
  const elements = useElements();

  if (!stripe || !elements) {
    return <div></div>;
  }

  const handleSubmit = async (e: any) => {
    if (auth.currentUser?.uid) {
      await createCheckoutSession(auth.currentUser?.uid);
    }
  };

  return (
    <>
      <Box
        sx={{
          margin: "20px",
          padding: "20px",
          borderRadius: "5px",
          width: "500px",
          border: "1px solid lightGray",
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
        }}
      >
        <Box width={"100"} display={"flex"} justifyContent={"center"}>
          <Typography fontWeight={"600"}>Utwórz subskrybcję</Typography>
        </Box>
        <Box
          width={"100"}
          display={"flex"}
          justifyContent={"center"}
          marginTop={"10px"}
        >
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Subskrybuj
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default SubscribePanel;
