import { Grid, TextFieldProps } from "@mui/material";
import { Button, TextField, Box, Typography, Paper } from "@mui/material";
import { useState } from "react";
import { InputLabel } from "@mui/material";
import assets from "../../../assets";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, firestore } from "../../../configs/firebaseConfig";
import {
  collection,
  doc,
  getDocs,
  limit,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import colors from "../../../configs/colorConfig";
// import GoogleIcon from '@mui/icons-material/Google';

const paperStyle = {
  padding: 20,
  width: 420,
  height: 560,
};

const FormField = (props: TextFieldProps) => (
  <TextField
    {...props}
    margin="normal"
    required
    fullWidth
    // onChange={(e) => setUsername(e.target.value)}
  >
    {props.children}
  </TextField>
);

const fetchUsers = async (userEmail: string): Promise<string | null> => {
  try {
    const querySnapshot = await getDocs(
      query(
        collection(firestore, "WhiteList"),
        where("email", "==", userEmail),
        limit(1)
      )
    );
    if (querySnapshot.size > 0) {
      return querySnapshot.docs[0].id;
    } else {
      return null;
    }
    //setUsers(userData);
  } catch (error) {
    return null;
  }
};

type Props = {
  email: string | null;
};
const UserSetupPanel = ({ email }: Props) => {
  const [isLoadingCredentials, setIsLoadingCredentials] = useState(false);
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);
  const [credentials, setCredentials] = useState<{
    password: string;
    firstName: string;
    lastName: string;
  }>({ password: "", firstName: "", lastName: "" });
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [value, setValue] = useState<string | null>("");
  const [showNumbers, setShowNumbers] = useState<boolean>(false);
  const navigate = useNavigate();

  const handlePasswordSetup = async (e: any) => {
    if (credentials.password.length < 6) {
      setErrorMessage("Hasło musi mieć co najmniej 6 znaków");
      return;
    }
    if (credentials.firstName.length === 0) {
      setErrorMessage("Należy podać wszyskie dane");
      return;
    }
    if (credentials.lastName.length === 0) {
      setErrorMessage("Należy podać wszyskie dane");
      return;
    }

    e.preventDefault();

    // Get email from query string
    if (email) {
      let id = await fetchUsers(email);
      createUserWithEmailAndPassword(auth, email, credentials.password)
        .then((userCredential) => {
          if (id) {
            const userDoc = doc(firestore, "WhiteList", id);
            updateDoc(userDoc, {
              firstName: credentials.firstName,
              lastName: credentials.lastName,
              createdAt: new Date(),
            });
          }

          navigate("/czat");
        })
        .catch((error) => {
          // Handle errors
          console.error(error);
        });
    }
  };

  return (
    <Paper style={paperStyle}>
      <Box
        sx={{
          marginTop: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          sx={{
            width: 80,
            border: "3px solid black",
          }}
          alt="logo"
          src={assets.icons.logo}
          marginBottom={5}
          marginTop={3}
        />
        <Typography fontSize={"24px"}>Podaj dane</Typography>
        <InputLabel style={{ color: "firebrick", height: "20px" }}>
          {errorMessage}
        </InputLabel>
        <Box>
          <FormField
            label="Hasło"
            value={credentials.password}
            onChange={(e) =>
              setCredentials({ ...credentials, password: e.target.value })
            }
            type="password"
          />
          <FormField
            label="imię"
            value={credentials.firstName}
            onChange={(e) =>
              setCredentials({ ...credentials, firstName: e.target.value })
            }
          />
          <FormField
            label="nazwisko"
            value={credentials.lastName}
            onChange={(e) =>
              setCredentials({ ...credentials, lastName: e.target.value })
            }
          />

          <Grid container>
            <Grid item xs>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={handlePasswordSetup}
                sx={{
                  backgroundColor: colors.primary,
                  mt: 3,
                  height: "40px",
                }}
              >
                Kontynuuj
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
};

export default UserSetupPanel;
