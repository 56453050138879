import assets from "../../assets";
import { BlogArticleModel } from "../../models/BlogArticle";

export const blogArticles: BlogArticleModel[] = [
  {
    image: assets.blog.image1_1,
    title: "Nowe narzędzie prawnicze",
    introduction:
      "<a href='https://prawmi.pl' style='color:#915AC2;'>PrawMi</a> to nowa <strong>inteligentna wyszukiwarka</strong> prawnicza wspierana przez AI i właśnie zadebiutowała na rynku! Skupiamy się w niej na <strong>cyfryzacji usług prawnych</strong>.",
    text: [
      {
        image: assets.blog.image1_1,
      },
      {
        sectionTitle: "",
        text: "Dzięki <strong>prostym w użyciu interfejsie </strong> pozwala ona na szybkie wprowadzenie zapytania i zwracanie najbardziej <strong>relewantnych artykułów</strong> ustaw oraz fragmentów orzeczeń. <strong>PrawMi</strong> to idealne rozwiązanie dla prawników, którzy pragną <strong>optymalizować czas pracy</strong> i <strong>automatyzować codzienne obowiązki<strong>. W prostych słowach: PrawMi jest <strong>prostym narzędziem prawniczym</strong> w kategorii <strong>LegalTech</strong>.",
      },
      {
        sectionTitle: "PrawMi - Jak się z nim Prawi?",
        text: "<a href='https://prawmi.pl' style='color:#915AC2;'>PrawMi</a> działa na zasadzie integracji, <strong>sztucznej inteligencji bazy: artykułów, ustaw, orzeczeń i wyszukiwarki.</strong> Po wprowadzeniu zapytania do <strong> mobilnej aplikacji dla prawników</strong>, system przeszukuje <strong>bazy danych prawnych online</strong>, aby znaleźć najbardziej odpowiednie artykuły ustaw i fragmenty orzeczeń. Dzięki <strong>elektronicznemu archiwizowaniu dokumentów prawnych </strong>, wszystkie znalezione informacje są łatwo dostępne i dobrze zorganizowane. PrawMi to <strong> narzędzie do analizy danych prawnych</strong>. Chat po znalezieniu danych, zwraca komentarz do wyszukanych zapytań, co umożliwia <strong>prawnikom</strong> lepsze zrozumienie i interpretację przepisów. Dodatkowo, <strong> elektroniczne rozliczanie czasu pracy i usługi doradcze w zakresie efektywności pracy prawnej</strong> sprawiają, że prawnicy mogą skupić się na najważniejszych aspektach swojej pracy, minimalizując czas spędzony na rutynowych zadaniach. ",
      },
      {
        image: assets.blog.image1_2,
      },
      {
        sectionTitle: "Jak PrawMi się rozPrawia z nieefektywną pracą.",
        text: "Korzystanie z PrawMi znacząco podnosi efektywność pracy prawników. Dzięki <strong>automatyzacji pracy i elektronicznemu zarządzaniu dokumentacją prawną</strong>, można szybko znaleźć potrzebne artykuły i orzeczenia, co oszczędza czas i redukuje koszty. To <strong>elektroniczne narzędzie prawne (LegalTech)</strong>, wspiera <strong>research prawny</strong> poprzez dostęp do najnowszych przepisów. Wszystko to sprawia, że prawnicy zyskują <strong>przewagę nad konkurencją</strong>, oferując klientom szybsze i bardziej precyzyjne usługi. <strong>Rozwiązania chmurowe dla branży prawnej</strong> zapewniają bezpieczeństwo przechowywanych danych oraz ich dostępność z dowolnego miejsca.",
      },
      {
        image: assets.blog.image1_3,
      },
      {
        sectionTitle: "Jak stać się PrawMikiem? Darmowy okres próbny.",
        text: "Rozumiemy, że inwestycje niosą za sobą pewne ryzyko, dlatego oferujemy nowym użytkownikom możliwość skorzystania z <strong>darmowego, miesięcznego okresu próbnego</strong>. W tym czasie prawnicy mogą przetestować wszystkie funkcje aplikacji bez żadnych kosztów, oceniając jej przydatność w codziennej pracy. Po zakończeniu okresu próbnego, PrawMi działa na zasadzie <strong>miesięcznej subskrypcji</strong>, co daje użytkownikom elastyczność w zarządzaniu usługą i możliwość łatwego wycofania się w razie potrzeby. Dzięki temu PrawMi gwarantuje <strong>pełne wsparcie techniczne i rozwojowe</strong>, dostosowując się do indywidualnych potrzeb każdego klienta.",
      },
      {
        centeredText:
          "<a href='https://prawmi.pl' style='color:#915AC2;'>Przetestuj już teraz!</a>",
      },
    ],
    date: new Date(2024, 5, 25),
    url: "nowe-narzedzie-prawnicze",
  },
];
