import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    pl: {
      instruction: require("./locales/pl/instruction.json"),
      dashboard: require("./locales/pl/dashboard.json"),
      start: require("./locales/pl/start.json"),
    },
    fr: {
      instruction: require("./locales/fr/instruction.json"),
      dashboard: require("./locales/fr/dashboard.json"),
      start: require("./locales/fr/start.json"),
    },
  },
  lng: process.env.REACT_APP_LANGUAGE,
  fallbackLng: "pl",
  interpolation: {
    escapeValue: false,
  },
});
