import { Box, Typography } from "@mui/material";

type Props = {
    keys: string[]
}
const ShortcutKeys = ({keys}:Props) => {
    return ( 
        <Box display="flex">
            {
                keys.map((key, index) => {
                    return (
                        <Box key={index} sx={{marginLeft: "10px", border:"1px solid lightGray", borderRadius:"3px", px:"7px", py:"2px"}}>
                           <Typography fontSize={"14px"} color="gray">{key}</Typography>
                        </Box>
                    )
                })
            }
        </Box>
     );
}
 
export default ShortcutKeys;