import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useState } from "react";
import { LawModel, lawFromMap } from "../../../../models/LawModel";
import axios from "../../../../api/axios";
import { auth } from "../../../../configs/firebaseConfig";
import useTabs from "../../../../hooks/useTabs";
import { useParams } from "react-router-dom";
import useSearchBar from "../../../../hooks/useSearchBar";
import { useTranslation } from "react-i18next";
import TextFieldWithPlaceholder from "../../atoms/textfield-with-placeholder/TextFieldWithPlaceholder";

const SearchLawPanel = () => {
  const { t } = useTranslation();

  const { addRuling, setDisplayedCase } = useTabs();
  const { text, setText, results, setResults } = useSearchBar();
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();

  const searchLaws = async () => {
    setLoading(true);
    const token = await auth.currentUser?.getIdToken();
    await axios
      .get(`/lawmodel/acts?acts_query=${text}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          accept: "*",
          "content-type": "application/json",
        },
      })
      .then((res) => {
        let searchedLaws: LawModel[] = [];
        let givenLaws = res.data;

        givenLaws.forEach((givenLaw: any) => {
          searchedLaws.push(lawFromMap(givenLaw));
        });
        setResults([...searchedLaws]);
      });
    setLoading(false);
  };

  const handleKeyPress = (e: any) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      searchLaws();
    }
  };

  const handleShowRuling = (law: LawModel) => {
    addRuling(law, id);
    setDisplayedCase(law);
  };

  return (
    <Box width={"100%"} height={"100%"}>
      <Box width={"100%"} px={"10px"}>
        <Typography fontSize={"12px"} color={"dimgray"}>
          {t("dashboard:search.searchLaw")}
        </Typography>
        <Box display={"flex"}>
          <div className="searchTextfield" style={{ width: "100%" }}>
            <TextFieldWithPlaceholder
              value={text}
              onKeyDown={handleKeyPress}
              onChange={(e) => setText(e.target.value)}
              placeholderNode={
                <div>
                  np.: <i>"uokk"</i> lub <i>"konkurencja"</i> lub{" "}
                  <i>"2007 331"</i>
                </div>
              }
            />
          </div>
          <Button
            size="small"
            variant="contained"
            color="primary"
            sx={{ ml: "10px", textTransform: "none" }}
            onClick={() => {
              searchLaws();
            }}
          >
            {t("dashboard:search.search")}
          </Button>
        </Box>
      </Box>
      {loading ? (
        <Box
          width={"100%"}
          height={"100px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box width={"100%"} marginTop={"20px"}>
          {results.map((law, index) => {
            return (
              <Box
                component={"button"}
                sx={{
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "lightGray",
                  },
                }}
                key={index}
                width={"100%"}
                p={"10px"}
                borderBottom={"1px solid #DBDBDB"}
                onClick={() => {
                  handleShowRuling(law);
                }}
                alignContent={"left"}
                alignItems={"left"}
              >
                <Typography align="left">{law.longTitle}</Typography>
                <Typography align="left" fontSize={"12px"} fontWeight={500}>
                  {law.title}
                </Typography>
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default SearchLawPanel;
