import { useContext } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../../context/Auth";

const RequireAuth = ({ allowedRoles, allowedUsers }: any) => {
    const location = useLocation();
    const {currentUser} = useContext(AuthContext)

    return (
        currentUser
            // roles?.find(role => allowedRoles?.includes(role))
            ? <Outlet />
            : <Navigate to='/logowanie' state={{ from: location }} replace />
        // <Outlet />
    );
}

export default RequireAuth;