import { useEffect, useRef, useState } from "react";
import {
  LawModel,
  PartModel,
  dataToTree,
} from "../../../../../../models/LawModel";
import "./LawPanel.css";
import axiosPrivate from "../../../../../../api/axios";
import { auth } from "../../../../../../configs/firebaseConfig";
import ArticleView, { ArticleModel } from "./components/law-parts/ArticleView";
import useTextHighlight from "../../../../../../hooks/useTextHighlight";
import { RulingPopoverModel } from "../../RightSideBar";
import useTabs from "../../../../../../hooks/useTabs";
import DefaultElementPart from "./components/law-parts/DefaultElementPart";
import LoadingWrapper from "../../../../../../components/loading-wrapper/LoadingWrapper";
import axios, { CancelTokenSource } from "axios";

export interface LawPanelData {
  listOfParts: PartModel[];
  listOfArticleNames: string[];
  highlightedArticle: string;
}

export interface PathModel {
  article: string;
  paragraph?: string;
  point?: string;
  letter?: string;
}

type Props = {
  law: LawModel;
  onParagraphSet: (number: number) => void;
  lawPanelData: LawPanelData;
  onLawPanelDataSet: (data: LawPanelData) => void;
  onArticlePositionsSet: (
    positions: { name: string; position: number }[]
  ) => void;
  onLawHammerClick: (data: RulingPopoverModel) => void;
};
const LawPanel = ({
  law,
  onParagraphSet,
  lawPanelData,
  onLawPanelDataSet,
  onArticlePositionsSet,
  onLawHammerClick,
}: Props) => {
  const targetRefs = useRef<Array<HTMLDivElement | null>>([]);
  const componentRef = useRef<HTMLDivElement | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { blockedText } = useTextHighlight();
  const [listOfArticleModels, setListOfArticleModels] = useState<
    ArticleModel[]
  >([]);
  const { displayed } = useTabs();
  const cancelTokenSourceRef = useRef<CancelTokenSource | null>(null);
  const lawUrl = useRef<string>("");

  const getData = async () => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel(
        "Operation canceled due to new request."
      );
    }
    cancelTokenSourceRef.current = axios.CancelToken.source();

    const token = await auth?.currentUser?.getIdToken();
    let listOfArticles: ArticleModel[] = [];
    setLoading(true);

    await axiosPrivate
      .get(`/postgresdb/act?unified_link=${law.url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          accept: "*",
          "content-type": "application/json",
        },
        responseType: "json",
        cancelToken: cancelTokenSourceRef.current.token,
      })
      .then((response) => {
        console.log(response);
        console.log("==============================");
        let rp = Object.values(response.data.rulings_paths);
        let sections = dataToTree(response.data.law_structure, law, rp);
        let listOfNames: string[] = sections.listOfArticles.map((m) => m.name);
        onLawPanelDataSet({
          listOfParts: [...sections.sections],
          listOfArticleNames: listOfNames,
          highlightedArticle: (displayed as LawModel).highlightedArticle ?? "",
        });
        listOfArticles = sections.listOfArticles;
        setListOfArticleModels([...listOfArticles]);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (listOfArticleModels.length > 0) {
      let elements = Array.from(
        document.getElementsByClassName("highlightedDiv")
      );
      if (elements.length > 0) {
        let position = elements[0].getBoundingClientRect();
        onParagraphSet(position.y - 100);
      }
    }
    //eslint-disable-next-line
  }, [listOfArticleModels]);

  useEffect(() => {
    if (lawPanelData.listOfParts?.length > 0) {
      let list: { name: string; position: number }[] = [];
      lawPanelData.listOfArticleNames.forEach((m) => {
        let element = document.getElementById(
          m.replaceAll(".", "").replaceAll(" ", "")
        );
        list.push({ name: m, position: element ? element.offsetTop : 0 });
      });
      onArticlePositionsSet(list);
    }

    //eslint-disable-next-line
  }, [lawPanelData.listOfArticleNames]);

  useEffect(() => {
    lawUrl.current = law.url;
    const fetchData = async () => {
      if (cancelTokenSourceRef.current) {
        cancelTokenSourceRef.current.cancel("Component unmounted.");
      }
      console.log("fetching data");
      console.log("lawPanelData.listOfParts");
      console.log(lawPanelData.listOfParts);
      if (
        lawPanelData.listOfParts?.length === 0 ||
        lawPanelData.listOfParts == null
      ) {
        console.log("fetching data");
        await getData();
      } else if (
        (displayed as LawModel).highlightedArticle !==
        lawPanelData.highlightedArticle
      ) {
        await getData();
      } else {
        onLawPanelDataSet({
          listOfParts: lawPanelData.listOfParts,
          listOfArticleNames: lawPanelData.listOfArticleNames,
          highlightedArticle: (displayed as LawModel).highlightedArticle ?? "",
        });
      }
    };

    fetchData();
    //eslint-disable-next-line
  }, [law]);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    numberOfRulings: number,
    path: PathModel
  ) => {
    onLawHammerClick({
      endpoint: `Dz. U. z ${law.year} r. poz. ${law.number}`,
      path: path,
      anchor: event.currentTarget.id,
      numberOfRulings: numberOfRulings,
      law: law,
      loadedRulings: [],
      visibility: true,
    });
  };

  const createRef = () => (element: HTMLDivElement | null) => {
    targetRefs.current[targetRefs.current.length] = element;
  };

  return (
    <div style={{ display: "flex", width: "100%" }} ref={componentRef}>
      <LoadingWrapper isLoading={loading} height={"300px"}>
        <div>
          <div style={{ userSelect: blockedText ? "none" : "text" }}>
            <label style={{ textAlign: "center", display: "block" }}>
              {law.title}
            </label>
            <label
              style={{
                textAlign: "center",
                fontWeight: "bold",
                display: "block",
                fontSize: "20px",
              }}
            >
              {law.longTitle}
            </label>
          </div>
          {(lawPanelData.listOfParts ?? []).map((part, indexPart) => {
            return (
              <div style={{ userSelect: blockedText ? "none" : "text" }}>
                <label style={{ marginLeft: "10px" }}>{part.name}</label>
                <label style={{ marginLeft: "10px" }}>{part.text}</label>
                <DefaultElementPart
                  items={part.books}
                  renderSubItems={(book, indexBook) => (
                    <DefaultElementPart
                      items={book.titles}
                      renderSubItems={(title, indexTitle) => (
                        <DefaultElementPart
                          items={title.sections}
                          renderSubItems={(section, indexSection) => (
                            <DefaultElementPart
                              items={section.chapters}
                              renderSubItems={(chapter, indexChapter) => (
                                <DefaultElementPart
                                  items={chapter.subchapters}
                                  renderSubItems={(
                                    subchapter,
                                    indexSubchapter
                                  ) =>
                                    subchapter.articles.map(
                                      (
                                        article: ArticleModel,
                                        indexArticle: number
                                      ) => {
                                        return (
                                          <>
                                            <div
                                              id={article.name
                                                .replaceAll(".", "")
                                                .replaceAll(" ", "")}
                                              ref={createRef()}
                                            ></div>
                                            <ArticleView
                                              key={indexArticle}
                                              article={article}
                                              onClick={handleClick}
                                            />
                                          </>
                                        );
                                      }
                                    )
                                  }
                                />
                              )}
                            />
                          )}
                        />
                      )}
                    />
                  )}
                />
              </div>
            );
          })}
        </div>
      </LoadingWrapper>
    </div>
  );
};

export default LawPanel;
