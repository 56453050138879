import React, { useEffect, useRef, useState } from "react";
import "firebase/auth";
import { isSignInWithEmailLink } from "firebase/auth";
import { auth } from "../../../configs/firebaseConfig";
import { Box, Grid, Grow, Typography } from "@mui/material";
import UserSetupPanel from "./UserSetupPanel";
import colors from "../../../configs/colorConfig";
import { useNavigate } from "react-router-dom";

const UserSetupPage = () => {
  const navigate = useNavigate();
  const [givenEmail, setGivenEmail] = useState<string | null>(null);

  useEffect(() => {
    let email = null;
    if (isSignInWithEmailLink(auth, window.location.href)) {
      email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        const url = new URL(window.location.href);
        email = url.searchParams.get("cartId");
      }
    } else {
      //window.location.href = '/email-submission';
    }
    setGivenEmail(email);
  }, []);

  return (
    <Grid
      sx={{
        height: "100vh",
        width: "100vw",
        background: colors.mainBg,
      }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{
          height: "100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <Box
          component={"button"}
          position={"absolute"}
          top={"10px"}
          left={"10px"}
          textTransform={"none"}
          onClick={() => {
            navigate("/");
          }}
        >
          {/* <Typography
            fontSize={"24px"}
            fontFamily={"Lato"}
            fontWeight={900}
            color={colors.primary}
          >
            PrawMi
          </Typography> */}
        </Box>
        <Grow
          in={true}
          style={{ transformOrigin: "0 0 0" }}
          {...(true ? { timeout: 500 } : {})}
        >
          <Box>
            <UserSetupPanel email={givenEmail} />
          </Box>
        </Grow>
      </Grid>
    </Grid>
  );
};

export default UserSetupPage;
