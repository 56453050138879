import Hero from "./compnents/hero/Hero";
import Intro from "./compnents/Intro";
import Services from "./compnents/services/Services";
import Footer from "./compnents/Footer";
import AskQuestionForm from "./compnents/ask-question-form/AskQuestionForm";
import CookieConsent from "react-cookie-consent";
import colors from "../../../configs/colorConfig";
import { Box, Divider } from "@mui/material";
import Seo from "./compnents/Seo";
import { useTranslation } from "react-i18next";

const StartPage = () => {
  const { t } = useTranslation();

  return (
    <Box display={"flex"} justifyContent={"center"} width={"100vw"}>
      <Box maxWidth={"2000px"}>
        <Hero />
        <Intro />
        <Services />
        {/* <Portfolio /> */}
        {/* <Testimonies /> */}
        <AskQuestionForm />
        <Divider />
        <Seo />
        <Footer />
        <CookieConsent
          location="bottom"
          buttonText="Rozumiem"
          cookieName="myAwesomeCookieName"
          style={{ background: "#2B373B" }}
          buttonStyle={{
            background: colors.primary,
            color: "white",
            fontSize: "13px",
          }}
          expires={150}
        >
          {t("start:cookies")}
        </CookieConsent>
      </Box>
    </Box>
  );
};

export default StartPage;
