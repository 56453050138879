import React from "react";
import { Box, IconButton } from "@mui/material";
import ParagraphView, { ParagraphModel } from "./ParagraphView";
import assets from "../../../../../../../../assets";
import colors from "../../../../../../../../configs/colorConfig";
import { PathModel } from "../../LawPanel";

export interface ArticleModel {
  name: string;
  text: string;
  highlighted?: boolean;
  paragraphs: ParagraphModel[];
  numberOfRulings: number;
}

type Props = {
  article: ArticleModel;
  onClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    rulingsNumber: number,
    path: PathModel
  ) => void;
};

const ArticleView = ({ article, onClick }: Props) => {
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    numberOfRulings: number,
    path?: PathModel
  ) => {
    let model: PathModel = {
      article: "",
    };

    if (path != null) {
      model = path;
    }
    model.article = article.name;

    onClick(event, numberOfRulings, model);
  };

  return (
    <div
      style={{
        backgroundColor:
          article.highlighted && article.name !== "" ? "#D6EBF2" : "",
        border:
          article.highlighted && article.name !== "" ? "1px solid gray" : "",
        borderRadius: "10px",
        paddingRight: "5px",
      }}
    >
      <div style={{ marginBottom: "0px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {article.numberOfRulings > 0 ? (
            <IconButton
              id={"art" + article.name.replaceAll(" ", "")}
              onClick={(e) => handleClick(e, article.numberOfRulings)}
            >
              <Box
                borderRadius={"5px"}
                component={"img"}
                src={assets.icons.hammerBlack}
                width="15px"
                height={"15px"}
              />
            </IconButton>
          ) : (
            <IconButton disabled={true} sx={{ padding: "6px" }}>
              <Box width="15px" height={"15px"} />
            </IconButton>
          )}
          <div
            className="article"
            dangerouslySetInnerHTML={{
              __html: "<h4>" + article.name + "</h4>",
            }}
          />
        </div>
        <div
          className={
            article.highlighted && article.name !== ""
              ? "highlightedDiv"
              : "normalDiv"
          }
          style={{ marginLeft: "30px" }}
          dangerouslySetInnerHTML={{ __html: article.text }}
        />
        {article.paragraphs.map((paragraph, indexParagraph) => {
          return (
            <div
              style={{
                backgroundColor:
                  paragraph.highlighted === true &&
                  article.highlighted === false &&
                  paragraph.name !== ""
                    ? colors.highlightedText
                    : "",
                border:
                  paragraph.highlighted === true &&
                  article.highlighted === false &&
                  paragraph.name !== ""
                    ? "1px solid gray"
                    : "",
                borderRadius: "10px",
                paddingRight: "5px",
              }}
            >
              <ParagraphView
                articleName={article.name}
                key={indexParagraph}
                paragraph={paragraph}
                onClick={handleClick}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ArticleView;
