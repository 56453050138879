import { Box, styled } from "@mui/material";
import useTextHighlight from "../../../../../../../../hooks/useTextHighlight";
import { ReactNode } from "react";

type Item = {
  name: string;
  text: string;
  [key: string]: any; // for additional properties
};

type SingleElementPartProps = {
  name: string;
  text: string;
  children: ReactNode;
};

const SingleElementPart = ({
  name,
  text,
  children,
}: SingleElementPartProps) => {
  const { blockedText } = useTextHighlight();
  const StyledBox = styled(Box)(({ theme }) => ({
    userSelect: blockedText ? "none" : "text",
  }));

  return (
    <>
      {name !== "" || text !== "" ? (
        <StyledBox>
          <div
            style={{ marginLeft: "10px" }}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </StyledBox>
      ) : null}
      {children}
    </>
  );
};

type Props = {
  items: Item[];
  renderSubItems: (item: Item, index: number) => React.ReactNode;
};
const DefaultElementPart = ({ items, renderSubItems }: Props) => {
  return (
    <>
      {items.map((item, index) => (
        <SingleElementPart name={item.name} text={item.text}>
          {renderSubItems && renderSubItems(item, index)}
        </SingleElementPart>
      ))}
    </>
  );
};

export default DefaultElementPart;
