import { Autocomplete, Box, Button, IconButton, TextField, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useState } from "react";


type Props = {
    listOfArticleNames: string[]
    value: string
    onArticleSelected: (value: string) => void
}
const GoBackButton = ({ listOfArticleNames, value, onArticleSelected }: Props) => {

    const [autocompleteVisibility, setAutocompleteVisibility] = useState<boolean>(false);

    const handleNext = () => {
        let index = listOfArticleNames.indexOf(value);
        if (index < listOfArticleNames.length - 1) {
            onArticleSelected(listOfArticleNames[index + 1])
        }
    }

    const handlePrevious = () => {
        let index = listOfArticleNames.indexOf(value);
        if (index < listOfArticleNames.length - 1) {
            onArticleSelected(listOfArticleNames[index - 1])
        }
    }

    const handleSelect = (selectedValue: string | null) => {
        if (selectedValue) {
            setAutocompleteVisibility(false)
            let index = listOfArticleNames.map((option) => option.replaceAll("<sup>", "-").replaceAll("</sup>", "")).indexOf(selectedValue);
            onArticleSelected(listOfArticleNames[index])
        }
    }

    return (
        <Box
            display={"flex"} justifyContent={"space-between"}
            sx={{
                width: "170px",
                height: "35px",
                zIndex: 1000,
                background: "lightGray",
                position: "absolute",
                right: "10px",
                top: "30px",
                borderRadius: "10px",
                opacity: "0.7",
            }}>
            <IconButton sx={{
                color: "black",
                padding: "4px",
            }}
                onClick={handlePrevious}>
                <KeyboardArrowLeftIcon  sx={{
            }}/>
            </IconButton>
            {
                autocompleteVisibility ?
                    <Autocomplete
                        sx={{ width: "110px" }}
                        size="small"
                        autoHighlight={true}
                        open={autocompleteVisibility}
                        onChange={(e, v) => { handleSelect(v) }}
                        onBlur={() => setAutocompleteVisibility(false)}
                        renderInput={(params) => {
                            return (
                                <TextField
                                    autoFocus={true}
                                    {...params}
                                    sx={{ '& .MuiInputBase-root': { fontSize: '13px' } }}
                                />
                            )
                        }}
                        options={listOfArticleNames.map((option) => option.replaceAll("<sup>", "-").replaceAll("</sup>", ""))}
                        //getOptionLabel={option => option.replaceAll("<sup>", "-").replaceAll("</sup>", "")}
                        renderOption={(props, option, { inputValue }) => {
                            const label = option
                            if(label !== ""){
                                return (
                                    <Typography {...props}>
                                        {label}
                                    </Typography>
                                );
                            }
                        }}
                        componentsProps={{
                            popper: {
                                sx: {
                                    '& .MuiAutocomplete-option': { fontSize: '13px', margin: '0px' },
                                },
                            },
                        }} />
                    :
                    <Button sx={{
                        textTransform:"none"
                    }} onClick={() => setAutocompleteVisibility(true)} >
                        <div dangerouslySetInnerHTML={{ __html: ("<p style='font-size:13px; font-weight:bold'>"+value+"</p>") }} />
                    </Button>
            }

            <IconButton sx={{
                color: "black",
                padding: "4px",
            }}
                onClick={handleNext}>
                <KeyboardArrowRightIcon />
            </IconButton>
        </Box>
    )
}

export default GoBackButton;