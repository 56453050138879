const assets = {
    icons: {
        logo : process.env.REACT_APP_ENV === "production" ? require('./logo3.png') : require('./logo4.png'),
        building : require('./icons/building.svg').default,
        // homephoto: require('./images/image9.png'),
        homephoto: require('./images/desk.jpg'),
        hammer: require('./icons/hammer.png'),
        hammerBlack: require('./icons/hammerBlack.png'),
        paper: require('./icons/paper.png'),
        aIcon: require('./icons/aIcon.png'),
        aLetter: require('./icons/aLetter.png'),
        uLetter: require('./icons/uLetter.png'),
        googleIcon: require('./icons/googleIcon.png'),
    },
    images:{
        dasboardSources: require('./images/dashboard.png'),
        dasboardLaw: require('./images/dashboard-law.png'),
        // personMan: require('./images/person1.png'),
        // personWoman: require('./images/person2.png'),
        Natasza: require('./images/Natasza5.jpeg'),
        Wiktoria: require('./images/Wiktoria2.jpeg'),
    },
    blog:{
        // image_title: require('./blog/t1.jpeg'),
        image1_1: require('./blog/art1-start.png'),
        image1_2: require('./blog/art1-dashboard.png'),
        image1_3: require('./blog/art1-graph.png'),
    }
}

export default assets;