import { CaseModel } from "./CaseModel";

/** Model reprezentujący orzeczenie*/
export interface RulingModel extends CaseModel {
  startIndex: number;
  text?: string;
  endIndex: number;
  judgmentDate?: string;
  judgmentType?: string;
}

export const rulingFromMap = (data: RulingBackendModel): RulingModel => {
  return {
    url: data.link,
    type: "ruling",
    text: data.text_excerpt
      .replace(/[\n\r]/g, " ")
      .split(" ")
      .join(" "),
    title: data.court_case,
    startIndex: data.start_index ?? 0,
    endIndex: data.end_index ?? 0,
  };
};

/** Model reprezentujący orzeczenie  w formacie zwracanym przez backend */
interface RulingBackendModel {
  start_index: number;
  end_index: number;
  link: string;
  text_excerpt: string;
  court_case: string;
}
