import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { routes } from "../../../routes/routes";
import { pathToRegexp } from "path-to-regexp";
import DashboardLayout from "../../../layouts/dashboard-layout/DashboardLayout";
import PublicLayout from "../../../public-module/layouts/public-layout/PublicLayout";
import EmptyLayout from "../../../public-module/layouts/empty-layout/EmptyLayout";

export default function MainLayout() {
  const location = useLocation();
  const [layout, setLayout] = useState<"dashboard" | "public" | "empty">(
    "public"
  );

  useEffect(() => {
    const route = routes.find((route) => {
      const regex = pathToRegexp(route.path);
      return regex.test(location.pathname);
    });

    setLayout(route?.layout || "public");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  if (layout === "dashboard") {
    return <DashboardLayout />;
  } else if (layout === "public") {
    return <PublicLayout />;
  } else {
    return <EmptyLayout />;
  }
}
