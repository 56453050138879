import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import assets from "../../../../../assets";
import "./gallery.css";
import colors from "../../../../../configs/colorConfig";

const Gallery = () => {
  const images = [
    {
      original: assets.images.dasboardSources,
      thumbnail: assets.images.dasboardSources,
    },
    {
      original: assets.images.dasboardLaw,
      thumbnail: assets.images.dasboardLaw,
    },
    // Add more images as needed
  ];

  return (
    <div style={{ border: `1px solid ${colors.sidebarLeft.bg}` }}>
      <ImageGallery
        items={images}
        showFullscreenButton={true}
        showPlayButton={false}
        showNav={true}
        showBullets={false}
        showThumbnails={false}
        additionalClass="my-gallery"
        showIndex={false}
      />
    </div>
  );
};

export default Gallery;
