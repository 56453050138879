import { LawModel, lawFromMap } from "../models/LawModel";
import { RulingModel, rulingFromMap } from "../models/RulingModel";
import axios from "./axios";

export const getConversationId = async (
  token: string,
  question: string,
  conversationId?: string | undefined
): Promise<string> => {
  let output = "";

  let query = {
    query: {
      conversation_id: conversationId,
      user_message: question,
    },
  };

  await axios
    .post("interface", query, {
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "*",
        "content-type": "application/json",
      },
    })
    .then((response) => {
      output = response.data.conversation_id;
    });
  return output;
};

export const getArticlesAsSources = async (
  token: string,
  conversationId: string
): Promise<LawModel[]> => {
  let sources: LawModel[] = [];
  console.log("ppp");
  console.log(conversationId);
  await axios
    .get(`interface/articles/${conversationId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "*",
        "content-type": "application/json",
      },
    })
    .then((response) => {
      if (response.data.articles) {
        response.data.articles.forEach((model: any) => {
          let law: LawModel = lawFromMap(model);
          law.rulings = [];
          law.rulingsLoading = true;
          sources.push(law);
        });
      }
    });
  return sources;
};

export const getRulingsAsSources = async (
  token: string,
  conversationId: string,
  article: LawModel
): Promise<RulingModel[]> => {
  let sources: RulingModel[] = [];

  await axios
    .get(`interface/rulings/${conversationId}?path_json=${article.pathJson}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "*",
        "content-type": "application/json",
      },
    })
    .then((response) => {
      if (response.data.rulings) {
        response.data.rulings.forEach((model: any) => {
          let ruling: RulingModel = rulingFromMap(model);
          sources.push(ruling);
        });
      }
    });
  console.log("out", sources);
  return sources;
};
