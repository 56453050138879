import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import { LawModel } from "../../../../../../models/LawModel";
import { RulingModel } from "../../../../../../models/RulingModel";
import LawItem from "./LawItem";
import RulingItem from "./RulingItem";
import styles from "./listOfSources.module.scss";
import { useState } from "react";

type Props = {
  source: LawModel;
  onRulingSelect: (source: RulingModel) => void;
  onLawSelect: (source: LawModel) => void;
};

const Source = ({ source, onRulingSelect, onLawSelect }: Props) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <Box marginBottom={"10px"}>
        <Box
          sx={{
            borderRadius: "10px",
            border: "1px solid lightGray",
            padding: "10px",
            background: "white",
          }}
        >
          <LawItem
            // key={sourIndex}
            source={source}
            onSourceSelect={(s) => {
              onLawSelect(s);
            }}
          />

          <Box
            className={`${styles.textContainer} ${
              expanded ? styles.expanded : ""
            }`}
          >
            <Box
              className={styles.fadeOut}
              component={"button"}
              onClick={() => {
                setExpanded(!expanded);
              }}
            ></Box>
            {(source.rulingsLoading === false ||
              source.rulingsLoading == null) &&
            source.rulings.length === 0 ? (
              <Box />
            ) : (
              <Box marginTop={"10px"}>
                <Divider />
              </Box>
            )}

            <Box>
              <Box
                component={"button"}
                onClick={() => {
                  setExpanded(!expanded);
                }}
              >
                {(source.rulingsLoading === false ||
                  source.rulingsLoading == null) &&
                source.rulings.length === 0 ? (
                  <Box />
                ) : (
                  <Typography>
                    Lista interesujących orzeczeń odnoszących się do powyższej ustawy:{" "}
                  </Typography>
                )}
              </Box>
              {source.rulingsLoading === true && (
                <Box
                  paddingBottom={"15px"}
                  paddingTop={"5px"}
                  display={"flex"}
                  alignContent={"center"}
                  alignItems={"center"}
                  justifyContent={"left"}
                >
                  <CircularProgress
                    size={"22px"}
                    sx={{
                      marginRight: "10px",
                      marginLeft: "20px",
                    }}
                  />
                  <Typography sx={{ fontStyle: "italic", color: "darkgrey" }}>
                    Wyszukiwanie orzeczeń
                  </Typography>
                </Box>
              )}
            </Box>

            {source.rulings &&
              source.rulings.map((ruling, rulIndex) => (
                <>
                  <Box paddingLeft={"30px"}>
                    {rulIndex > 0 && <Divider />}

                    <RulingItem
                      // key={rulIndex}
                      source={ruling}
                      gridProps={{ mb: "20px" }}
                      onSourceSelect={(s) => {
                        onRulingSelect(s);
                      }}
                    />
                  </Box>
                </>
              ))}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Source;
