import React from "react";
import colors from "../../../configs/colorConfig";

export const AboutPage = () => {
  return (
    <div>
      <div
        style={{ width: "100vw", display: "flex", justifyContent: "center" }}
      >
        <div style={{ maxWidth: "700px", marginTop: "100px" }}>
          <h1 style={{ color: colors.primary, fontSize: "24px" }}>O nas</h1>
          <p style={{ fontSize: "16px" }}>
            Jesteśmy zespołem składającym się z programistów oraz prawników
            pochdzących z Krakowa i Warszawy. Nasza różnorodność pozwala nam
            spojrzeć na projekty z różnych perspektyw, co owocuje innowacyjnymi
            rozwiązaniami.
          </p>
          <p style={{ fontSize: "16px" }}>
            Nasi programiści to doświadczeni specjaliści, którzy potrafią
            przekuć najtrudniejsze wyzwania na funkcjonalne rozwiązania.
          </p>
          <p style={{ fontSize: "16px" }}>
            Jesteśmy również wspierani przez wykwalifikowanego prawnika, co daje
            naszym klientom pewność, że ich interesy są zabezpieczone na każdym
            etapie projektu.
          </p>
          <p style={{ fontSize: "16px" }}>
            Jeśli szukasz zespołu, który łączy w sobie kreatywność,
            profesjonalizm i skuteczność, to właśnie nas znalazłeś.
          </p>
        </div>
      </div>
    </div>
  );
};
