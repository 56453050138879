import { useEffect, useState } from "react";
import "./RulingPanel.css";
import { RulingModel } from "../../../../../../models/RulingModel";
import useTextHighlight from "../../../../../../hooks/useTextHighlight";
import { Box, styled } from "@mui/material";
import loadingGif from "../../../../../../assets/gif/wedges-big.gif";
import colors from "../../../../../../configs/colorConfig";
import axios from "../../../../../../api/axios";
import { auth } from "../../../../../../configs/firebaseConfig";

//ruling url examle: https://www.saos.org.pl/api/judgments/491500

type Props = {
  ruling: RulingModel;
  onHtmlTextSet: (htmlText: string) => void;
  htmlText: string | null;
  scrollToFragment: boolean;
};
const RulingPanel = ({
  ruling,
  onHtmlTextSet,
  htmlText,
  scrollToFragment,
}: Props) => {
  const { blockedText } = useTextHighlight();
  const [loading, setLoading] = useState(false);

  const StyledBox = styled(Box)(({ theme }) => ({
    userSelect: blockedText ? "none" : "text",
  }));

  const [rulingData, setRulingData] = useState<any>();

  // const getTypeFromText = (text: string) => {
  //     switch (text) {
  //         case "SENTENCE":
  //             return "Wyrok"
  //         case "REASONS":
  //             return "Uzasadnienie"
  //         default:
  //             return "Typ nieznany"
  //     }
  // }

  const fetchData = async () => {
    setLoading(true);

    const token = await auth?.currentUser?.getIdToken();
    await axios
      .get(
        `/lawmodel/ruling_text?ruling_link=${ruling.url}&start_index=${ruling.startIndex}&end_index=${ruling.endIndex}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            accept: "*",
            "content-type": "application/json",
          },
          responseType: "json",
        }
      )
      .then((res) => {
        if (res.data.length > 0) {
          let startIndex = res.data.indexOf("<RUL>") - 1;
          let endIndex = res.data.indexOf("</RUL>") - 5;

          let rulingDataHiglighted = highlightText(
            res.data.replace("<RUL>", "").replace("</RUL>", ""),
            startIndex,
            endIndex
          );
          let ruling = rulingDataHiglighted;

          if (ruling.includes("</p>") && ruling.includes("</div>")) {
            onHtmlTextSet(ruling);
          } else {
            let vcc = ruling
              .replace(/<|>/g, (m: string) => m + " ")
              .replace(/[^\S\r\n]+/g, " ");
            onHtmlTextSet("<p>" + vcc + "</p>");
          }
        }
      });
    setLoading(false);
  };

  useEffect(() => {
    if (!htmlText && htmlText !== "") {
      fetchData();
    }
    // else if (scrollToFragment) {
    //     const element = document.getElementById('overlay-span');
    //     if (element) {
    //         element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    //     }
    // }
    //eslint-disable-next-line
  }, [ruling]);

  useEffect(() => {
    if (loading === false) {
      const element = document.getElementById("overlay-span");
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [loading]);

  function highlightText(html: string, ind1: number, ind2: number): string {
    let textCount = 0;
    let inTag = false;
    let result = "";
    let count = 0;
    for (let i = 0; i < html.length; i++) {
      if (html[i] === "<" && html[i + 1] !== " ") {
        inTag = true;
        if (textCount >= ind1 && textCount < ind2) {
          result += "</span>";
        }
      }

      if (!inTag) {
        if (textCount === ind1) {
          if (count === 0) {
            result += `<span id="overlay-span" style="background-color:${colors.highlightedText};">`;
            count++;
          } else {
            result += `<span style="background-color:${colors.highlightedText};">`;
          }
        }
      }
      textCount++;
      result += html[i];

      if (html[i] === ">" && html[i - 1] !== " ") {
        inTag = false;
        if (textCount > ind1 && textCount <= ind2) {
          result += `<span style="background-color:${colors.highlightedText};">`;
        }
      }
    }

    if (textCount >= ind1 && textCount <= ind2) {
      result += "</span>";
    }

    return result;
  }

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "300px",
          }}
        >
          <img
            width={"60px"}
            height={"60px"}
            src={loadingGif}
            alt="loading..."
          />
        </div>
      ) : (
        <StyledBox>
          <Box
            sx={{
              marginBottom: "10px",
            }}
          >
            <h3>{ruling.title + " - Pełny tekst orzeczenia"}</h3>
            {rulingData && (
              <>
                <h6 style={{ fontWeight: "bold" }}>
                  {rulingData.judgmentType.toUpperCase()}
                </h6>
                <h6>{"Data orzeczenia: " + rulingData?.date}</h6>
                {rulingData?.judges && rulingData?.judges.length > 0 && (
                  <h6>
                    {"Sędziowie: " +
                      rulingData?.judges
                        .map((judge: any) => judge.name)
                        .join(", ")}
                  </h6>
                )}
              </>
            )}
          </Box>
          {htmlText && <div dangerouslySetInnerHTML={{ __html: htmlText }} />}
        </StyledBox>
      )}
    </div>
  );
};
export default RulingPanel;
