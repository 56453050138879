import {
  Box,
  BoxProps,
  CircularProgress,
  Fade,
  Grid,
  TextField,
  TextFieldProps,
  Typography,
  TypographyProps,
  styled,
} from "@mui/material";
import axios from "../../../../api/axios";
import SendIcon from "@mui/icons-material/Send";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import colors from "../../../../configs/colorConfig";
import { auth, firestore } from "../../../../configs/firebaseConfig";
import InputAdornment from "@mui/material/InputAdornment";
import { doc, getDoc } from "firebase/firestore";
import { useLocation, useNavigate } from "react-router-dom";
import useSidebar from "../../../../hooks/useSidebar";
import { LawModel, lawFromMap } from "../../../../models/LawModel";
import { RulingModel, rulingFromMap } from "../../../../models/RulingModel";
import useTabs from "../../../../hooks/useTabs";
import BotResponse from "./bot-response/BotResponse";
import { useTranslation } from "react-i18next";
import {
  getArticlesAsSources,
  getConversationId,
  getRulingsAsSources,
} from "../../../../api/api-interface";

type TxtProps = {
  textfieldProps: TextFieldProps;
  onClick: () => void;
  disabled: boolean;
};
const QuestionTextField = ({ textfieldProps, disabled, onClick }: TxtProps) => (
  <TextField
    inputRef={textfieldProps.inputRef}
    value={textfieldProps.value}
    onChange={textfieldProps.onChange}
    sx={{ background: "white", fontSize: "14px" }}
    fullWidth
    multiline
    size="small"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SendIcon
            color={disabled ? "disabled" : "primary"}
            onClick={() => {
              if (!disabled) {
                onClick();
              }
            }}
            style={{ cursor: disabled ? "default" : "pointer" }}
          />
        </InputAdornment>
      ),
    }}
    {...textfieldProps}
  >
    {textfieldProps.children}
  </TextField>
);

const QuestionTypography = styled(Typography)<TypographyProps>({
  color: colors.chat.userTextColor,
  fontSize: "14px",
});

const UserTypography = styled(Typography)<TypographyProps>({
  color: colors.chat.userTextColor,
  marginRight: "1px",
  fontSize: "14px",
  fontWeight: "bold",
  display: "inline-block",
});

const BotTypography = styled(Typography)<TypographyProps>({
  color: colors.chat.userTextColor,
  marginRight: "1px",
  fontSize: "14px",
  fontWeight: "bold",
  display: "inline-block",
});

const BoxForSuggestions = styled(Box)<BoxProps>({
  border: "1px solid lightGray",
  borderRadius: "10px",
  minHeight: "50px",
  padding: "7px",
  textAlign: "left",
  "&:hover": {
    backgroundColor: "white",
  },
});

const TypographySuggestion = styled(Typography)<TypographyProps>({
  fontSize: "14px",
  margin: "auto",
  fontWeight: "500",
});

type Props = {
  onSourecsChange: (sources: LawModel[]) => void;
  onSourcesLoading: (value: boolean) => void;
  focusOnInput: boolean;
  chatId: string | undefined;
};
const ChatFeed = ({
  onSourecsChange,
  onSourcesLoading,
  focusOnInput,
  chatId,
}: Props) => {
  const { t } = useTranslation();

  const [currentResponse, setCurrentResponse] = useState<string>("");
  const [currentQuestion, setCurrentQuestion] = useState<string>("");
  const [listOfQuestions, setListOfQuestions] = useState<string[]>([]);
  const [listOfResponses, setListOfResponses] = useState<string[]>([]);
  const [showHelperQuestions, setShowHelperQuestions] = useState<number>(0);
  const [conversationId, setConverstionId] = useState<string>("");
  const [responseIsRendering, setResponseIsRendering] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const textFieldRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { addToast } = useSidebar();
  const { setDisplayedCase, setRulingList } = useTabs();
  const gridRef = useRef<HTMLDivElement | null>(null);
  const location = useLocation();
  const [generatingAnswer, setGeneratingAnswer] = useState<boolean>(false);
  const chatStreamResponse = useRef<string>("");
  const suggestedQuestions = [
    "Jakie warunki musi spełnić przedsiębiorstwo, by uznać, że przedsiębiorstwo nadużywa pozycji dominującej? Czy przedsiębiorstwo, które posiada mniej niż 40% udziału w rynku może zostać uznane za przedsiębiorstwo posiadające pozycję dominującą?",
    "Czy można zawrzeć umowę dzierżawy obiektu magazynowego (centrum logistyczne) w miejsce umowy najmu (istnieje potrzeba sprostania wymogowi z art. 41b ustawy o odpadach)? Jakie są ew. skutki i czy możemy uznać, że taka działalność może opierać się na pobieraniu jakichś pożytków (np. pożytków cywilnych)?",
  ];

  // const findUserData = async () => {
  //   let userData = null;
  //   const querySnapshot = await getDocs(
  //     query(
  //       collection(firestore, "Users"),
  //       where("email", "==", auth.currentUser?.email)
  //     )
  //   );
  //   querySnapshot.forEach((doc) => {
  //     userData = doc.data();
  //     if (userData?.showInstructions == null) {
  //       setShowHelperQuestions(true);
  //     }
  //   });
  // };

  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, [focusOnInput]);

  useEffect(() => {
    //findUserData();
  }, []);

  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.scrollTop = gridRef.current.scrollHeight;
    }
  }, [listOfQuestions, currentResponse]);

  useEffect(() => {
    if (chatId) {
      fetchConversation(chatId);
    } else {
      setListOfQuestions([]);
      setListOfResponses([]);
      onSourecsChange([]);
      setConverstionId("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);

  const fetchConversation = async (id: string) => {
    let userConvo: string[] = [];
    let botConvo: string[] = [];
    let laws: LawModel[] = [];
    setConverstionId(id);
    const docRef = doc(firestore, "Conversations", id);

    await getDoc(docRef).then((doc) => {
      let messages = doc.data()?.messages;
      let sources = doc.data()?.sources;
      let tabs = doc.data()?.tabs ?? [];
      setRulingList(tabs);
      messages?.forEach((message: any, index: number) => {
        if (index % 2 === 0 && index > 0) {
          botConvo.push(message.content);
        } else if (index % 2 === 1) {
          userConvo.push(message.content);
        }
      });
      sources?.forEach((source: any) => {
        let rulings: RulingModel[] = [];
        console.log("source.rulings", source.rulings);
        if (source.rulings != null) {
          source.rulings.forEach((ruling: any) => {
            let r: RulingModel = rulingFromMap(ruling);
            rulings.push(r);
          });
        }

        let law: LawModel = lawFromMap(source.act);
        law.rulings = rulings;
        laws.push(law);
      });
      onSourecsChange(laws);
      setListOfQuestions(userConvo);
      setListOfResponses(botConvo);
    });
  };

  const handleClick = async (question?: string) => {
    setDisplayedCase("sources");
    if (auth.currentUser == null) return;

    const token = await auth.currentUser.getIdToken();

    setListOfQuestions([...listOfQuestions, question ?? currentQuestion]);
    setResponseIsRendering(true);
    chatStreamResponse.current = "";
    let replyId: string | null = null;
    onSourcesLoading(true);
    setIsLoading(true);
    if (conversationId == null || conversationId === "") {
      replyId = await getConversationId(token, question ?? currentQuestion);
      addToast({
        message: "Rozpoczęto nową rozmowę",
        type: "success",
        id: new Date(),
        title: "dd",
      });
    } else {
      replyId = await getConversationId(
        token,
        question ?? currentQuestion,
        conversationId
      );
    }
    let sources = await getArticlesAsSources(token, replyId);
    onSourcesLoading(false);
    onSourecsChange(sources);

    if (replyId != null) {
      const promises = sources.map((model) =>
        getRulingsAsSources(token, replyId!, model)
      );
      const results = await Promise.all(promises);
      results.forEach((ruls: RulingModel[], index: number) => {
        sources[index].rulings = ruls;
        sources[index].rulingsLoading = false;
      });
    }
    onSourecsChange([...sources]);
    setGeneratingAnswer(true);
    setIsLoading(false);

    await axios.get(`interface/stream/${replyId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "*",
        "content-type": "application/json",
      },
      responseType: "json",
      onDownloadProgress: (progressEvent) => {
        const xhr = progressEvent.event.currentTarget;
        if (xhr) {
          const { responseText } = xhr;
          let data = responseText
            .substring(5)
            .replaceAll("data: \r", "\n\r")
            .replaceAll("\r\n\r\ndata: ", "")
            .replaceAll("\r\n\r\n", "");
          chatStreamResponse.current = data.replaceAll("\r\ndata: ", "");
          setCurrentResponse(chatStreamResponse.current);
        }
      },
    });
    if (replyId) {
      fetchConversation(replyId);
    }
    setResponseIsRendering(false);
    setCurrentQuestion("");
    setListOfResponses([...listOfResponses, chatStreamResponse.current]);
    setCurrentResponse("");
    if (listOfResponses.length === 0 && replyId) {
      navigate(replyId);
    }
  };

  const handleKeyPress = (e: any) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      handleClick();
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentQuestion(e.target.value);
  };

  useEffect(() => {
    setShowHelperQuestions(showHelperQuestions + 1);
    if (location.pathname === "/czat") {
      if (textFieldRef.current) {
        textFieldRef.current.focus();
      }
      setIsLoading(false);
      setCurrentResponse("");
      setResponseIsRendering(false);
      setCurrentQuestion("");
      setGeneratingAnswer(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Grid
      container
      pl={1}
      direction="column"
      height={"100%"}
      className="leftSidePanel"
    >
      <Grid
        ref={gridRef}
        item
        xs
        pr={0}
        pt={3}
        style={{ flexGrow: 1, overflowY: "auto" }}
      >
        {listOfQuestions.map((question, index) => (
          <Grid container key={index} px={2}>
            <Grid item xs={12} py={1}>
              <Box
                sx={{
                  background: "white",
                  border: "1px solid lightGray",
                  borderRadius: "10px",
                  padding: "10px",
                  marginLeft: "20px",
                }}
              >
                <Box display={"flex"}>
                  <Box
                    sx={{
                      py: "3px",
                      px: "8px",
                      borderRadius: "8px",
                      background: "#eeeeee",
                      display: "inline-block",
                    }}
                  >
                    <UserTypography>{t("dashboard:chat.you")}</UserTypography>
                  </Box>
                </Box>
                <QuestionTypography>{question}</QuestionTypography>
              </Box>
            </Grid>
            <Grid item xs={12} py={1}>
              <Box
                sx={{
                  background: "white",
                  border: "1px solid lightGray",
                  borderRadius: "10px",
                  padding: "10px",
                  marginRight: "20px",
                }}
              >
                <Box
                  sx={{
                    py: "3px",
                    px: "8px",
                    borderRadius: "8px",
                    background: "#eeeeee",
                    display: "inline-block",
                  }}
                >
                  <BotTypography>{"PrawMi"}</BotTypography>
                </Box>
                <>
                  {index === listOfResponses.length ? (
                    <Box>
                      {isLoading ? (
                        <CircularProgress
                          size={"22px"}
                          sx={{ margin: "auto", marginTop: "10px" }}
                        />
                      ) : (
                        <BotResponse
                          data-testid="response-display"
                          responseText={currentResponse}
                        />
                      )}
                    </Box>
                  ) : (
                    <>
                      <BotResponse responseText={listOfResponses[index]} />
                    </>
                  )}
                </>
              </Box>
            </Grid>
          </Grid>
        ))}
      </Grid>

      <Grid item>
        <Grid container>
          {location.pathname === "/czat" &&
            isLoading === false &&
            generatingAnswer === false &&
            showHelperQuestions < 2 && (
              <>
                <Box width={"100%"} display={"flex"} justifyContent={"center"}>
                  <Typography sx={{ fontSize: "14px", fontWeight: "700" }}>
                    {t("dashboard:chat.whatToKnow")}
                  </Typography>
                </Box>
                <Grid item xs={12} px={3}>
                  {suggestedQuestions.map((question, index) => (
                    <Fade
                      in={true}
                      key={index}
                      timeout={{ enter: 1000 * (index + 1), exit: 1000 }}
                    >
                      <BoxForSuggestions
                        marginTop={"10px"}
                        component={"button"}
                        key={index}
                        onClick={() => {
                          handleClick(question);
                        }}
                      >
                        <TypographySuggestion align="left">
                          {question}
                        </TypographySuggestion>
                      </BoxForSuggestions>
                    </Fade>
                  ))}
                </Grid>
              </>
            )}

          <Grid item xs={12} display={"flex"} p={3}>
            <div className={"questionDiv"} style={{ width: "100%" }}>
              <QuestionTextField
                data-testid="ask-question-button"
                textfieldProps={{
                  value: !responseIsRendering ? currentQuestion : "",
                  inputRef: textFieldRef,
                  onKeyDown: (e) => handleKeyPress(e),
                  onChange: handleOnChange,
                }}
                disabled={currentQuestion.trim() === "" || responseIsRendering}
                onClick={handleClick}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChatFeed;
