import React from "react";

import { useTranslation } from "react-i18next";
import colors from "../../../../../configs/colorConfig";
import ServiceTile from "./ServiceTile";
import img from "../../../../../assets/images/hosting4.svg";
import img2 from "../../../../../assets/images/web4.svg";
import img3 from "../../../../../assets/images/app4.svg";
import img4 from "../../../../../assets/images/consultation4.svg";

const Services = () => {
  const { t } = useTranslation();

  return (
    <div id="narzedzia" className="py-12" style={{ background: colors.grayBG }}>
      <section data-aos="zoom-in-down">
        <div className="my-4 py-4">
          <h4
            className="my-2 text-center text-3xl font-bold"
            style={{ color: colors.primary }}
          >
            {t("start:services.tools")}
          </h4>

          <div className="flex justify-center">
            <div
              className="w-24 border-b-4"
              style={{ color: colors.primary }}
            ></div>
          </div>
        </div>

        <div className="px-12" data-aos="fade-down" data-aos-delay="600">
          <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
            <ServiceTile
              title={t("start:services.serviceTileOneTitle")}
              text={t("start:services.serviceTileOneText")}
              image={img}
            />
            <ServiceTile
              title={t("start:services.serviceTileTwoTitle")}
              text={t("start:services.serviceTilTwoText")}
              image={img2}
            />
            <ServiceTile
              title={t("start:services.serviceTileThreeTitle")}
              text={t("start:services.serviceTileThreeText")}
              image={img3}
            />
            <ServiceTile
              title={t("start:services.serviceTileFourTitle")}
              text={t("start:services.serviceTileFourText")}
              image={img4}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
