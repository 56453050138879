import React, { useContext, useEffect, useRef, useState } from "react";
import SplitPaneContext from "../../../../context/SplitPaneContext";
import style from "./splitPane.module.scss";
import useTextHighlight from "../../../../hooks/useTextHighlight";

const SplitPane = ({ children, ...props }: { children: React.ReactNode }) => {
  const [clientHeight, setClientHeight] = useState<number | null>(null);
  const [clientWidth, setClientWidth] = useState<number | null>(null);
  const { setBlockedText } = useTextHighlight();
  const yDividerPos = useRef<number | null>(null);
  const xDividerPos = useRef<number | null>(null);

  const onMouseHoldDown = (event: React.MouseEvent<Element, MouseEvent>) => {
    setBlockedText(true);
    yDividerPos.current = event.clientY;
    xDividerPos.current = event.clientX;
  };

  const onMouseHoldUp = () => {
    if (!yDividerPos.current && !xDividerPos.current) {
      return;
    }
    yDividerPos.current = null;
    xDividerPos.current = null;
    window?.getSelection()?.removeAllRanges();

    setBlockedText(false);
  };

  const onMouseHoldMove = (e: any) => {
    if (!yDividerPos.current && !xDividerPos.current) {
      return;
    }

    const newYDividerPos = yDividerPos.current ?? 0;
    const newXDividerPos = xDividerPos.current ?? 0;

    setClientHeight(clientHeight + e.clientY - newYDividerPos);
    setClientWidth(clientWidth + e.clientX - newXDividerPos);

    yDividerPos.current = e.clientY;
    xDividerPos.current = e.clientX;
  };

  useEffect(() => {
    document.addEventListener("mouseup", onMouseHoldUp);
    document.addEventListener("mousemove", onMouseHoldMove);

    return () => {
      document.removeEventListener("mouseup", onMouseHoldUp);
      document.removeEventListener("mousemove", onMouseHoldMove);
    };
  });

  return (
    <div
      {...props}
      className={style["split-pane-row"]}
      style={{ width: "calc(100vw - 85px)" }}
    >
      <SplitPaneContext.Provider
        value={{
          clientHeight,
          setClientHeight,
          clientWidth,
          setClientWidth,
          onMouseHoldDown,
        }}
      >
        {children}
      </SplitPaneContext.Provider>
    </div>
  );
};

export const Divider = (
  props: JSX.IntrinsicAttributes &
    React.ClassAttributes<HTMLDivElement> &
    React.HTMLAttributes<HTMLDivElement>
) => {
  const { onMouseHoldDown } = useContext(SplitPaneContext);

  return (
    <div
      className={style["separator-col"]}
      {...props}
      onMouseDown={onMouseHoldDown}
    >
      <div
        style={{
          width: "1px",
          height: "100%",
          cursor: "col-resize",
          background: "lightGray",
          marginLeft: "2px",
        }}
      />
    </div>
  );
};

export const SplitPaneLeft = (
  props: JSX.IntrinsicAttributes &
    React.ClassAttributes<HTMLDivElement> &
    React.HTMLAttributes<HTMLDivElement>
) => {
  const topRef = useRef<HTMLDivElement>(null);
  const { clientWidth, setClientWidth } = useContext(SplitPaneContext);

  useEffect(() => {
    if (!clientWidth && topRef.current) {
      setClientWidth(topRef.current?.clientWidth * 0.7);
      return;
    }

    if (topRef.current) {
      topRef.current.style.minWidth = clientWidth + "px";
      topRef.current.style.maxWidth = clientWidth + "px";
    }
    //eslint-disable-next-line
  }, [clientWidth]);

  return <div {...props} className={style["split-pane-left"]} ref={topRef} />;
};

export const SplitPaneRight = (
  props: JSX.IntrinsicAttributes &
    React.ClassAttributes<HTMLDivElement> &
    React.HTMLAttributes<HTMLDivElement>
) => {
  return <div {...props} className={style["split-pane-right"]}></div>;
};

export default SplitPane;
