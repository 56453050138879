import React, { useCallback, useState, createContext } from "react";
import { ToastModel } from "../models/ToastModel";

interface PropsBasicDataProvider {
  children: React.ReactNode;
}

interface ContextDataModel {
  // dodać model tosta
  toasts: ToastModel[];
  addToast: (toast: ToastModel) => void;
  removeToast: (toast: ToastModel) => void;
}

export const SidebarContext: React.Context<ContextDataModel> = createContext({
  toasts: [] as ToastModel[],
  addToast: (error: any) => {},
  removeToast: (error: any) => {},
});

export const SidebarProvider: any = ({ children }: PropsBasicDataProvider) => {
  // dodać model tosta
  const [toasts, setToasts] = useState<ToastModel[]>([]);

  function id(toast: ToastModel) {
    return toast.id;
  }

  // dodać model tosta
  const addToast = (toast: ToastModel) => {
    toast.id ??= new Date()
    setToasts([...toasts, toast]);
    return id(toast);
  };

  const removeToast = (toast: any) => {
    setToasts((toastsList: any[]) => {
      return toastsList.filter((current: any) => id(current) !== id(toast));
    });
  };

  const contextValue = {
    toasts: toasts,
    addToast: useCallback((error: any) => addToast(error), []),
    removeToast: useCallback((error: any) => removeToast(error), []),
  };

  return (
    <SidebarContext.Provider value={contextValue}>
      {children}
    </SidebarContext.Provider>
  );
};
