import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { Outlet, useNavigate } from "react-router-dom";
import colors from "../../../configs/colorConfig";

const EmptyLayout = () => {
  const navigate = useNavigate();
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Box
        component="main"
        sx={{
          height: "100vh",
          width: "100vw",
          background: colors.mainBg,
        }}
      >
        <Box
          component={"button"}
          position={"absolute"}
          top={"10px"}
          left={"10px"}
          textTransform={"none"}
          onClick={() => {
            navigate("/");
          }}
        >
          <Typography
            fontSize={"24px"}
            fontFamily={"Lato"}
            fontWeight={900}
            color={colors.primary}
          >
            PrawMi
          </Typography>
        </Box>
        <Outlet />
      </Box>
    </Box>
  );
};

export default EmptyLayout;
