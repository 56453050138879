import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import SubscribePanel from "./components/SubscribePanel";
import { useEffect, useState } from "react";
import { getPremiumStatus } from "./components/GetPremiumStatus";


const SubscriptionPage = () => {

    const [subscribedText, setSubscribedText] = useState<string>("")
    const [subscribed, setSubscribed] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const fetchSubscriptionInfo = async () => {
        setIsLoading(true)
        let subed: boolean = await getPremiumStatus();
        if (subed) {
            setSubscribedText("Zasubskrybowany")
        }
        else {
            setSubscribedText("Niezasubskrybowany")
        }

        setSubscribed(subed)
        setIsLoading(false)
    }

    useEffect(() => {
        fetchSubscriptionInfo()
    }, []);


    return (
        <Grid container display={"flex"} width={"100vw"} justifyContent={"center"} >
            <Grid >
                {
                    isLoading ?
                        <Box>
                            <CircularProgress />
                        </Box> :
                        <>
                            <Typography>{subscribedText}</Typography>
                            {
                                subscribed ? null :
                                    <SubscribePanel />
                            }
                        </>
                }
            </Grid>
        </Grid>
    );
}

export default SubscriptionPage;