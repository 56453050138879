import { Box, Typography, Paper } from "@mui/material";
import { InputLabel } from "@mui/material";
import assets from "../../../../assets";
import style from "./authenticationBox.module.scss";

type Props = {
  title: string;
  errorMessage: string;
  children: React.ReactNode;
};
const AuthenticationBox = ({ title, errorMessage, children }: Props) => {
  return (
    <Paper className={style.paperBox}>
      <Box className={style.mainDiv}>
        <Box
          className={style.logoBox}
          component="img"
          alt="logo"
          src={assets.icons.logo}
        />
        <Typography className={style.textTitle}>{title}</Typography>
        <InputLabel className={style.error}>{errorMessage}</InputLabel>
        <Box>{children}</Box>
      </Box>
    </Paper>
  );
};

export default AuthenticationBox;
