import { Box, Grid, GridProps, Typography, styled } from "@mui/material";
import { LawModel } from "../../../../../../models/LawModel";
import { Key, useEffect, useRef, useState } from "react";
import useTextHighlight from "../../../../../../hooks/useTextHighlight";

const MainGrid = styled(Grid)<GridProps>({});

type Props = {
  source: LawModel;
  onSourceSelect: (source: LawModel) => void;
};
const LawItem = ({ source, onSourceSelect }: Props) => {
  const { blockedText } = useTextHighlight();
  const gridRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false);

  const StyledBox = styled(Box)(({ theme }) => ({
    userSelect: blockedText ? "none" : "text",
  }));

  useEffect(() => {
    if (gridRef.current) {
      const ov =
        gridRef.current.scrollHeight > gridRef.current.clientHeight + 10;

      setIsOverflowing(ov);
    }
  }, [source]);

  return (
    <MainGrid container>
      <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
        <Box
          display={"flex"}
          alignItems={"center"}
          sx={{ marginBottom: "5px" }}
        >
          <Box display={"flex"} alignItems={"center"}>
            {/* <Button
              sx={{
                textTransform: "none",
                fontSize: "14px",
                fontWeight: "700",
                paddingLeft: "0px",
              }}
              onClick={() => {
                onSourceSelect(source);
              }}
            >
              {source.longTitle}
            </Button> */}
            <Box
              component={"button"}
              sx={{
                border: "1px solid #a0a0a0",
                paddingLeft: "5px",
                paddingRight: "5px",
                borderRadius: "5px",
                ":hover": {
                  background: "lightGray",
                },
              }}
              onClick={() => {
                onSourceSelect(source);
              }}
            >
              <Typography sx={{ width: "100%" }} textAlign={"left"}>
                {source.longTitle}
              </Typography>
              <Box width={"100%"} display={"flex"} justifyContent={"left"}>
                <div
                  style={{ userSelect: blockedText ? "none" : "text" }}
                  dangerouslySetInnerHTML={{
                    __html:
                      "<p style='font-weight:700;font-size:14px;'>" +
                      source.text?.articles[0].name +
                      "</p>",
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        {/* <Box>
          <Box
            sx={{ userSelect: blockedText ? "none" : "text" }}
            component={"img"}
            src={assets.icons.paper}
            width="20px"
            height={"20px"}
          />
        </Box> */}
      </Grid>

      <Grid
        item
        xs={12}
        style={{ maxHeight: "210px", overflow: "hidden" }}
        ref={gridRef}
      >
        {source.text?.articles && source.text?.articles.length > 0 ? (
          <>
            <StyledBox>
              <Typography fontSize={"14px"}>
                {source.text?.articles[0].text}
              </Typography>
            </StyledBox>
            {source.text?.articles[0].paragraphs.map(
              (
                paragraph: { name: string; text: string; points: any[] },
                index: Key | null | undefined
              ) => {
                return (
                  <>
                    <StyledBox key={index} display={"flex"}>
                      <p style={{ fontSize: "14px", marginRight: "3px" }}>
                        {paragraph.name.replaceAll(" ", "")}
                      </p>
                      {paragraph.text !== "" && paragraph.text !== null ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              "<p style=font-size:14px;'>" +
                              paragraph.text +
                              "</p>",
                          }}
                        />
                      ) : null}
                    </StyledBox>
                    {paragraph.points.map(
                      (
                        point: { name: string; text: string; letters: any[] },
                        index: Key | null | undefined
                      ) => {
                        return (
                          <Box paddingLeft={"10px"}>
                            <Box key={index} display={"flex"}>
                              <Typography
                                fontSize={"14px"}
                                sx={{ marginRight: "3px" }}
                              >
                                {point.name}
                              </Typography>
                              <Typography fontSize={"14px"}>
                                {point.text}
                              </Typography>
                            </Box>
                            {point.letters.map(
                              (
                                letter: { name: string; text: string },
                                index: Key | null | undefined
                              ) => {
                                return (
                                  <Box paddingLeft={"10px"}>
                                    <Box key={index} display={"flex"}>
                                      <Typography
                                        fontSize={"14px"}
                                        sx={{ marginRight: "3px" }}
                                      >
                                        {letter.name}
                                      </Typography>
                                      <Typography fontSize={"14px"}>
                                        {letter.text}
                                      </Typography>
                                    </Box>
                                  </Box>
                                );
                              }
                            )}
                          </Box>
                        );
                      }
                    )}
                  </>
                );
              }
            )}
          </>
        ) : null}
      </Grid>
      {isOverflowing && (
        <Box>
          <Typography style={{ marginLeft: "10px" }}>(...)</Typography>
        </Box>
      )}
    </MainGrid>
  );
};

export default LawItem;
