import data from '../secrets/firebaseConfig.json';
import { initializeApp } from "firebase/app";
import {getAuth, GoogleAuthProvider} from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
const firebaseConfig =data


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
// auth.languageCode = 'pl'; 
const firestore = getFirestore(app);
const provider = new GoogleAuthProvider();
export {auth,provider, firestore};