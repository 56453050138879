import LoginPanel from "../../components/organisms/login-panel/LoginPanel";
import Notificator from "../../../components/layout/notificator/Notificator";
import LoginRegistrationTemplate from "../../components/templates/login-registration-template/LoginRegistrationTemplate";

export const LoginPage = () => {
  return (
    <>
      <LoginRegistrationTemplate>
        <LoginPanel />
      </LoginRegistrationTemplate>
      <Notificator />
    </>
  );
};

export default LoginPage;
