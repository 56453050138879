import { IconButton, Tab, Tabs } from "@mui/material";
import useTabs from "../../../../../../hooks/useTabs";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import { steps } from "../../../../../../instructions/steps";
import { useTranslation } from "react-i18next";

const NavigationMenu = () => {
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();
  const { rulings } = useTabs();
  const { displayed, setDisplayedCase, removeRuling } = useTabs();

  const closeTab = (ruling: any) => {
    removeRuling(ruling, id);
    if (displayed === ruling) {
      setDisplayedCase("sources");
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    if (newValue === "search") {
      setDisplayedCase("search");
    } else if (newValue === "sources") {
      setDisplayedCase("sources");
    } else {
      let ruling = rulings.find((ruling) => ruling.url === newValue);
      if (ruling) {
        setDisplayedCase(ruling);
      }
    }
  };

  return (
    <Tabs
      className="tabSearch"
      value={
        displayed !== "search" && displayed !== "sources"
          ? displayed.url
          : displayed
      }
      onChange={handleChange}
      variant="scrollable"
      scrollButtons="auto"
      aria-label="scrollable auto tabs example"
      sx={{ padding: "0px", height: "28px", minHeight: "28px" }}
    >
      <Tab
        data-testid="search-tab"
        sx={{
          py: "0px",
          textTransform: "none",
          height: "28px",
          minHeight: "28px",
          "& .MuiTab-wrapper": {
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "0.875rem",
          },
        }}
        label={<SearchIcon />}
        value="search"
      />
      <Tab
        data-testid="sources-tab"
        sx={{
          py: "0px",
          textTransform: "none",
          height: "28px",
          minHeight: "28px",
          "& .MuiTab-wrapper": {
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "0.875rem",
          },
        }}
        label={t("dashboard:tabs.sources")}
        value="sources"
      />
      {rulings
        .filter((m) => m.type === "law")
        .map((ruling, index) => {
          return (
            <Tab
              data-testid="law-tab"
              key={index}
              sx={{
                padding: "0px",
                textTransform: "none",
                height: "28px",
                minHeight: "28px",
                mx: "8px",
              }}
              label={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <label style={{ marginRight: "5px" }}>
                    {ruling.year != null
                      ? ruling.year + " / " + ruling.number
                      : ruling.title}
                  </label>
                  <IconButton
                    size="small"
                    data-testid="close-button-id"
                    onClick={(event) => {
                      event.stopPropagation();
                      closeTab(ruling);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                </div>
              }
              value={ruling.url}
            />
          );
        })}
      {rulings
        .filter((m) => m.type === "ruling")
        .map((ruling, index) => {
          return (
            <Tab
              key={index}
              sx={{
                padding: "0px",
                textTransform: "none",
                height: "28px",
                minHeight: "28px",
                mx: "8px",
                background: "#B6acd6",
              }}
              label={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <label style={{ marginRight: "5px", paddingLeft: "5px" }}>
                    {ruling.year != null
                      ? ruling.year + " / " + ruling.number
                      : ruling.title}
                  </label>
                  <IconButton
                    size="small"
                    onClick={(event) => {
                      event.stopPropagation();
                      closeTab(ruling);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                </div>
              }
              value={ruling.url}
            />
          );
        })}
    </Tabs>
  );
};

export default NavigationMenu;
