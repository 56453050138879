import colors from "../../../configs/colorConfig";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { blogArticles } from "../../data/blogArticles";
import { useState } from "react";
import { BlogArticleModel } from "../../../models/BlogArticle";
import { LinkProps, styled } from "@mui/material";
import style from "./blogArticle.module.scss";

function formatDate(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };

  return date.toLocaleDateString("pl-PL", options);
}

const StyledLink = styled(Link)<LinkProps>({
  color: "white",
  backgroundColor: colors.primary,
  padding: "10px",
  borderRadius: "10px",
  textDecoration: "none",
  ":&hover": {
    color: "blue-900",
  },
});

const BlogArticle = () => {
  const location = useLocation();
  const [article, setArticle] = useState<BlogArticleModel | undefined>(
    undefined
  );

  useEffect(() => {
    let art = blogArticles.find(
      (article) => article.url === location.pathname.split("/")[2]
    );

    if (art) {
      setArticle(art);
    } else {
      console.log("Article not found");
    }
  }, [location]);

  return (
    <div>
      <div className={style.blogDiv}>
        <div className={style.blogArticleContainer}>
          <h1 className={style.titleText} style={{ color: colors.primary }}>
            {article?.title}
          </h1>
          <p className={style.dateText}>
            {article?.date ? formatDate(article.date) : ""}
          </p>

          <div style={{ margin: "60px" }}>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  "<p style='font-size:24px;text-align:center;'>" +
                  article?.introduction +
                  "</p>",
              }}
            ></div>
            {article?.text.map((section, index) => (
              <div key={index}>
                {section.centeredText && section.centeredText !== "" && (
                  <div
                    className={style.sectionTextDiv}
                    dangerouslySetInnerHTML={{
                      __html:
                        "<p style='font-size:24px;'>" +
                        section.centeredText +
                        "</p>",
                    }}
                  />
                )}
                {section.sectionTitle && section.sectionTitle !== "" && (
                  <h5 style={{ fontSize: "24px", paddingTop: "5px" }}>
                    {section.sectionTitle}
                  </h5>
                )}
                {section.text && section.text !== "" && (
                  <div
                    style={{
                      marginBottom: "20px",
                      marginTop: "5px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html:
                        "<p style='font-size:18px; text-align: justify;'>" +
                        section.text +
                        "</p>",
                    }}
                  />
                )}
                {section.image && (
                  <p>
                    <img
                      src={section.image}
                      alt="blog"
                      className={style.sectionImage}
                    />
                  </p>
                )}
              </div>
            ))}
          </div>
          <div className={style.bottomLinkBox}>
            <StyledLink to="/blog">Powrót do bloga</StyledLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogArticle;
