import {
  CircularProgress,
  Grid,
  Link,
  Modal,
  TextFieldProps,
} from "@mui/material";
import { Button, TextField, Box, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithPopup, sendSignInLinkToEmail, signOut } from "firebase/auth";
import { auth, provider, firestore } from "../../../../configs/firebaseConfig";
import {
  collection,
  doc,
  getDocs,
  limit,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import colors from "../../../../configs/colorConfig";
import assets from "../../../../assets";
import AuthenticationBox from "../../molecules/authentication-box/AuthenticationBox";

const FormField = (props: TextFieldProps) => (
  <TextField
    {...props}
    margin="normal"
    required
    fullWidth
    // onChange={(e) => setUsername(e.target.value)}
  >
    {props.children}
  </TextField>
);

const RegistrationPanel = () => {
  const [isLoadingCredentials, setIsLoadingCredentials] = useState(false);
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);
  const [credentials, setCredentials] = useState<{
    login: string;
    password: string;
  }>({ login: "", password: "" });
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showNumbers, setShowNumbers] = useState<boolean>(false);
  const [noAccessModal, setNoAccessModal] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleCredentialsChange = (
    value: string,
    key: "login" | "password"
  ) => {
    setErrorMessage("");
    setCredentials({ ...credentials, [key]: value });
  };

  const fetchUsers = async (userEmail: string): Promise<string | null> => {
    try {
      const querySnapshot = await getDocs(
        query(
          collection(firestore, "WhiteList"),
          where("email", "==", userEmail),
          limit(1)
        )
      );
      if (querySnapshot.size > 0) {
        return querySnapshot.docs[0].id;
      } else {
        return null;
      }
      //setUsers(userData);
    } catch (error) {
      return null;
    }
  };

  const handleLoginClick = async () => {
    if (validateEmail(credentials.login) == null) {
      setErrorMessage("Niepoprawny email");
      return;
    }
    setIsLoadingCredentials(true);

    fetchUsers(credentials.login).then((userId) => {
      if (userId) {
        const actionCodeSettings = {
          url: `https://prawmi.pl/dane?cartId=${credentials.login}`,
          handleCodeInApp: true,
        };

        sendSignInLinkToEmail(auth, credentials.login, actionCodeSettings)
          .then(() => {
            setShowNumbers(true);
            window.localStorage.setItem("emailForSignIn", credentials.login);
          })
          .catch((error) => {});
      } else {
        setErrorMessage("Nie masz dostępu do aplikacji");
        setNoAccessModal(true);
      }
    });
    setIsLoadingCredentials(false);
  };

  const handleGooleLoginClick = async () => {
    setIsLoadingGoogle(true);
    await signInWithPopup(auth, provider).then((data) => {
      if (data.user.email == null) {
        setErrorMessage("Nie masz dostępu do aplikacji");
      } else {
        fetchUsers(data.user.email).then((userId) => {
          if (userId) {
            const userDoc = doc(firestore, "WhiteList", userId);
            updateDoc(userDoc, {
              createdAt: new Date(),
            });
            navigate("/czat");
          } else {
            setErrorMessage("Nie masz dostępu do aplikacji");
            signOut(auth).catch((error) => {
              // An error happened.
            });
          }
        });
      }
    });
    setIsLoadingGoogle(false);
  };

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <AuthenticationBox
      title="Rejestracja"
      errorMessage={showNumbers ? errorMessage : " "}
    >
      <Box>
        {showNumbers ? (
          <>
            <Box display={"flex"} justifyContent={"center"} marginTop={"50px"}>
              <Typography textAlign={"center"}>
                Dziękujemy za rejestrację.{" "}
              </Typography>
            </Box>
            <Box display={"flex"} justifyContent={"center"} marginTop={"20px"}>
              <Typography textAlign={"center"}>
                Na podany adres otrzymasz wiadomość mailową z linkiem{" "}
              </Typography>
            </Box>
          </>
        ) : (
          <>
            <FormField
              label="Email"
              value={credentials.login}
              onChange={(e) => handleCredentialsChange(e.target.value, "login")}
              autoFocus
            />
            {isLoadingCredentials ? (
              <Grid
                container
                width={"100%"}
                marginTop={"10px"}
                height={"40px"}
                display={"flex"}
                justifyContent={"center"}
              >
                <CircularProgress size={"22px"} sx={{ margin: "auto" }} />
              </Grid>
            ) : (
              <Button
                fullWidth
                variant="contained"
                onClick={() => handleLoginClick()}
                sx={{
                  mt: 3,
                  height: "40px",
                  backgroundColor: colors.primary,
                }}
                disabled={isLoadingCredentials || isLoadingGoogle}
              >
                {/* <Typography padding="2px" fontSize={"12px"} fontWeight={"500"}>Zaloguj</Typography> */}
                Kontynuuj
              </Button>
            )}
            {isLoadingGoogle ? (
              <Grid
                container
                width={"100%"}
                marginTop={"10px"}
                height={"40px"}
                display={"flex"}
                justifyContent={"center"}
              >
                <CircularProgress size={"22px"} sx={{ margin: "auto" }} />
              </Grid>
            ) : (
              <Button
                fullWidth
                variant="contained"
                onClick={() => handleGooleLoginClick()}
                sx={{
                  mt: 3,
                  backgroundColor: "white",
                  height: "40px",
                  boxShadow: "0px 0px 0px 0px",
                  border: "1px solid black",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "white",
                  },
                }}
                disabled={isLoadingCredentials || isLoadingGoogle}
                startIcon={
                  <img
                    alt={"googleIcon"}
                    src={assets.icons.googleIcon}
                    style={{ width: "20px", height: "20px" }}
                  />
                }
              >
                {/* <Typography padding="2px" fontSize={"12px"} fontWeight={"500"}>Zaloguj</Typography> */}
                Zarejestruj przez Google
              </Button>
            )}
            <Grid container>
              <Grid
                item
                xs
                display={"flex"}
                justifyContent={"center"}
                py={"20px"}
              >
                <Typography sx={{ fontSize: "13px" }}>
                  Masz już konto?
                </Typography>
                <Link
                  href="/logowanie"
                  sx={{
                    fontSize: "13px",
                    textDecoration: "none",
                    color: colors.primary,
                    marginLeft: "5px",
                    ":hover": {
                      textDecoration: "underline",
                    },
                  }}
                  variant="body2"
                >
                  Zaloguj się
                </Link>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
      <Modal
        open={noAccessModal}
        onClose={() => {
          setNoAccessModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 700,
            bgcolor: "background.paper",
            border: "1px solid #ddd",
            borderRadius: "10px",
            boxShadow: 24,
            p: 2,
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              padding: "5px",
            }}
          >
            <p style={{ fontSize: "16px" }}>Witamy!</p>
          </div>
          <p style={{ fontSize: "14px", marginTop: "10px" }}>
            Dziękujemy za chęć skorzystania z PrawMi. Sprzedaż aplikacji jest
            obecnie w fazie beta. By móc się zarejestrować i wziąć udział w
            darmowym okresie testowania proszę skontakuj się wysyłając krótką
            wiadomość na: <strong>biuro@prawmi.pl.</strong>
          </p>
          <p style={{ fontSize: "14px", marginTop: "10px" }}>
            Pozdrawiamy serdecznie
          </p>
          <p style={{ fontSize: "14px", marginTop: "10px" }}>Zespół PrawMi</p>

          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Button
              variant="contained"
              sx={{ backgroundColor: colors.primary }}
              onClick={() => {
                setNoAccessModal(false);
              }}
              style={{ marginTop: "20px" }}
            >
              Zamknij
            </Button>
          </div>
        </Box>
      </Modal>
    </AuthenticationBox>
  );
};

export default RegistrationPanel;
