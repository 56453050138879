import { Link } from "@mui/material";
import { useState } from "react";

const Seo = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div style={{ paddingLeft: "70px", paddingRight: "70px" }}>
      <div style={{ marginTop: "10px", marginBottom: "30px" }}>
        <h4 className="font-bold text-4xl" style={{ fontSize: "1.875rem" }}>
          O nas
        </h4>
        <div className="text-md font-medium text-gray-600">
          <div>
            <div style={{ display: isExpanded ? "block" : "none" }}>
              <p style={{ fontSize: "16px" }}>
                Efektywne{" "}
                <strong>narzędzie do automatyzacji researchu prawnego</strong>{" "}
                PrawMi to innowacyjna <strong>aplikacja dla prawników</strong>,
                która oferuje zaawansowane rozwiązania z zakresu{" "}
                <strong>legal tech</strong>. Dzięki naszej platformie możesz
                szybko analizować{" "}
                <strong>ustawy, akty prawne, dokumenty sądowe </strong> oraz{" "}
                <strong> orzeczenia </strong>.
              </p>
              <p style={{ fontSize: "16px", marginTop: "10px" }}>
                Nasza aplikacja to nie tylko{" "}
                <strong>baza danych prawnych online</strong>, ale także{" "}
                <strong>elektroniczne narzędzie prawne</strong>, które znacznie
                poprawi Twoją <strong>efektywność pracy prawnej</strong>. Bez
                względu na to czy jesteś <strong>adwokatem/tką</strong>,{" "}
                <strong>radcą prawnym</strong> czy pracownikiem{" "}
                <strong>kancelarii prawnej</strong>, PrawMi zapewni Ci niezbędną
                pomoc w codziennej pracy.
              </p>
              <p style={{ fontSize: "16px", marginTop: "10px" }}>
                Dzięki wykorzystaniu{" "}
                <strong>sztucznej inteligencji (AI)</strong> nasz{" "}
                <strong>asystent badań prawniczych</strong> ułatwia
                przeprowadzanie <strong>researchu prawnego</strong>.
              </p>
            </div>
            <p
              style={{
                fontSize: "16px",
                display: isExpanded ? "none" : "block",
              }}
            >
              Efektywne{" "}
              <strong>narzędzie do automatyzacji researchu prawnego</strong>{" "}
              PrawMi to innowacyjna <strong>aplikacja dla prawników</strong>,
              która oferuje zaawansowane rozwiązania z zakresu{" "}
              <strong>legal tech</strong>.
            </p>

            <Link
              onClick={() => setIsExpanded(!isExpanded)}
              style={{ cursor: "pointer", fontSize: "16px" }}
            >
              {isExpanded ? "Zwiń" : "Więcej"}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Seo;
