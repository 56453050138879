import style from "./rodoPage.module.scss";

const RodoPage = () => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <label style={{ fontSize: "30px", color: "gray" }}>w budowie</label>
      {/* <div className={style.policyDiv}>
      <h2>Polityka Prywatności</h2>

      <h4 className={style.titleH4}>1. Administrator Danych</h4>

      <p>Administratorem Twoich danych osobowych jest prawmi.pl</p>

      <h4 className={style.titleH4}>2. Cele Przetwarzania Danych</h4>

      <p>
        Twoje dane osobowe są przetwarzane w celu umożliwienia świadczenia usług
        zgodnie z zawartymi umowami oraz w celach analitycznych i
        statystycznych.
      </p>

      <h4 className={style.titleH4}>
        3. Rodzaj przetwarzanych danych osobowych
      </h4>
      <p>
        Administrator może przetwarzać dane osobowe Użytkownika: imię i
        nazwisko, adres e-mail, numer telefonu.
      </p>

      <h4 className={style.titleH4}>4. Podstawa Prawna</h4>
      <p>Podstawą prawną przetwarzania Twoich danych osobowych jest:</p>

      <p> wyrażona przez Ciebie zgoda,</p>
      <p>niezbędność przetwarzania do wykonania umowy,</p>
      <p>
        niezbędność przetwarzania do wypełnienia obowiązku prawnego ciążącego na
        Administratorze,
      </p>
      <p>
        niezbędność przetwarzania do celów wynikających z prawnie uzasadnionych
        interesów realizowanych przez Administratora lub przez stronę trzecią.
      </p>

      <h4 className={style.titleH4}>5. Przysługujące Prawa</h4>
      <p>
        Przysługuje Ci prawo dostępu do treści Twoich danych osobowych, ich
        poprawiania, usunięcia, ograniczenia przetwarzania, przenoszenia,
        wniesienia sprzeciwu oraz prawo do cofnięcia zgody w dowolnym momencie
        bez wpływu na zgodność z prawem przetwarzania, którego dokonano na
        podstawie zgody przed jej cofnięciem.
      </p>

      <h4 className={style.titleH4}> 6. Bezpieczeństwo Danych</h4>
      <p>
        Dbamy o bezpieczeństwo Twoich danych osobowych stosując odpowiednie
        środki techniczne i organizacyjne.
      </p> 
    </div>*/}
    </div>
  );
};

export default RodoPage;
