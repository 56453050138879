import React, { useCallback, useState, createContext } from "react";

interface PropsBasicDataProvider {
  children: React.ReactNode;
}

interface ContextDataModel {
  id: string;
  setId: (id: string) => void;
}

export const ConversationContext: React.Context<ContextDataModel> = createContext({
  id: "" as string,
  setId: (error: any) => {}
});

export const ConversationProvider: any = ({ children }: PropsBasicDataProvider) => {

  const [localId, setLocoalId] = useState<string>("");

  const setId = (r: string) => {
    setLocoalId(r);
    return r
  }

  const contextValue = {
    id: localId,
    setId: useCallback((error: any) => setId(error), [])
  };

  return (
    <ConversationContext.Provider value={contextValue}>
      {children}
    </ConversationContext.Provider>
  );
};
