import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import { auth } from "../../../configs/firebaseConfig";

const AdminPage = () => {
  const [email, setEmail] = useState<string>("");

  const createUser = () => {
    createUserWithEmailAndPassword(auth, email, "bbb555")
      .then((authUser) => {})
      .catch((error) => {
        alert(error.message);
      });
  };

  return (
    <Grid
      height={"100vh"}
      width={"calc(100vw - 100px)"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      alignContent={"center"}
    >
      <Box
        width={"400px"}
        padding="30px"
        style={{
          border: "1px solid lightGray",
          borderRadius: "5px",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}
      >
        <Box width={"100%"} display={"flex"} justifyContent={"center"}>
          <Typography fontWeight={"600"}> Dodaj nowego użytkownika</Typography>
        </Box>
        <Box
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          marginTop={"20px"}
        >
          <TextField
            size={"small"}
            fullWidth
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            placeholder="podaj adres email"
          />
        </Box>
        <Box
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          marginTop={"20px"}
        >
          <Button
            variant="contained"
            onClick={() => {
              createUser();
            }}
          >
            Utwórz użytkownika
          </Button>
        </Box>
      </Box>
    </Grid>
  );
};

export default AdminPage;
