
import { Typography } from '@mui/material';
import React from 'react';

type Props = {
    text: string,
    image: any,
    title: string,
}

const ServiceTile = ({ text, image, title }: Props) => {
    return (
        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700  rounded-lg shadow-2xl p-3 group">
            <div style={{ height: "100%", display: "flex", flexDirection: "column" }} className="m-2 text-justify text-sm">
                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={image} />
                <h4 className="font-semibold my-4 text-2xl text-center">{title}</h4>
                <div style={{ flex: "1", display: "flex", alignItems: 'end', paddingBottom: "10px" }}>
                    <div  style={{height:"150px"}}>
                        <Typography  style={{fontSize:"16px", textAlign:"left"}}>
                            {text}
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceTile;