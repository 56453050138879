import { Grid, Typography } from "@mui/material";
import Switch from "@mui/material/Switch";
import style from "./userPreferences.module.scss";

const UserPreferences = () => {
  return (
    <div className={style.mainDiv}>
      <h2 className={style.settingsTitle}>Ustawienia</h2>
      <Grid container spacing={2} width={"100%"}>
        <Grid item xs={8} className={style.gridCell}>
          <Typography>Zapisywanie pozycji podzielonego widoku</Typography>
        </Grid>
        <Grid item xs={4} className={style.gridCell}>
          <Switch />
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
      </Grid>
    </div>
  );
};

export default UserPreferences;
