import React from "react";
import colors from "../../../../configs/colorConfig";
import Gallery from "./gallery/Gallery";
import { Link, LinkProps, styled } from "@mui/material";
import { useTranslation } from "react-i18next";

const StyledLink = styled(Link)<LinkProps>({
  background: colors.primary,
  color: "white",
  textDecoration: "none",
  "&&:hover": {
    background: colors.lightGray,
  },
  padding: "10px",
  borderRadius: "15px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "200px",
});

const Intro = () => {
  const { t } = useTranslation();

  return (
    <>
      <div style={{ padding: "50px" }} id="about">
        <div
          className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left"
          data-aos="fade-up"
        >
          <div className="lg:w-2/3 flex flex-col lg:mx-2 justify-center">
            <Gallery />
          </div>
          <div
            className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/3 pl-4"
            data-aos="zoom-in"
            data-aos-delay="500"
          >
            <h4
              className="text-3xl font-bold"
              style={{ color: colors.primary }}
            >
              {t("start:intro.title")}{" "}
            </h4>
            <div>
              <p className="my-3 text-xl text-gray-600 font-semibold">
                {t("start:intro.textOne")}{" "}
              </p>
            </div>
            <div>
              <p className="my-3 text-xl text-gray-600 font-semibold">
                {t("start:intro.textTwo")}{" "}
              </p>
            </div>

            <StyledLink href="#kontakt">
              {t("start:hero.button")}
              <svg
                className="w-4 h-4 ml-1 group-hover: translate-x-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </StyledLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default Intro;
