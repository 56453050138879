import { Box, CircularProgress, Modal, Typography } from "@mui/material";
import colors from "../../../configs/colorConfig";
import RightSideBar from "../../components/organisms/right-side-bar/RightSideBar";
import ChatFeed from "../../components/organisms/chat-feed/ChatFeed";
import { useCallback, useEffect, useState } from "react";
import ShortcutPanel from "../../../components/shortcut-panel/ShortcutPanel";
import { useParams } from "react-router";
import { LawModel } from "../../../models/LawModel";
import useTabs from "../../../hooks/useTabs";
import Joyride from "react-joyride";
import { steps } from "../../../instructions/steps";
import {
  collection,
  doc,
  getDocs,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import { auth, firestore } from "../../../configs/firebaseConfig";
import useInstruction from "../../../hooks/useInstruction";
import DashboardTemplate from "../../components/templates/dashboard-template/DashboardTemplate";

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid lightGray",
  borderRadius: "10px",
  boxShadow: 24,
  p: 2,
};

const DashboardPage = () => {
  const [sources, setSources] = useState<LawModel[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [cmdKeyValue, setCmdKeyValue] = useState<boolean>(false);
  const [focusOnInput, setFocusOnInput] = useState<boolean>(false);
  const [sourcesLoading, setSourcesLoading] = useState<boolean>(false);
  const { id } = useParams();
  const { setDisplayedCase } = useTabs();
  const [show, setShow] = useState<boolean>(false);
  const { showInstruction, setShowInstruction } = useInstruction();

  useEffect(() => {
    if (id != null) {
      setDisplayedCase("sources");
    } else {
      setDisplayedCase("search");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (showInstruction) {
      setShow(true);
      setShowInstruction(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showInstruction]);

  const findUserData = async () => {
    let userData = null;
    const querySnapshot = await getDocs(
      query(
        collection(firestore, "Users"),
        where("email", "==", auth.currentUser?.email)
      )
    );
    querySnapshot.forEach((doc) => {
      userData = doc.data();
      if (userData?.showInstructions == null) {
        setShow(true);
      }
    });
  };

  const updateUserInstructionStatus = async () => {
    if (auth.currentUser?.uid != null) {
      const updateDocRef = doc(firestore, "Users", auth.currentUser.uid);
      await updateDoc(updateDocRef, {
        showInstructions: false,
      });
    }
  };

  useEffect(() => {
    findUserData();
  }, []);

  const checkKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.metaKey && event.key === "/") {
        setOpenModal(true);
        setCmdKeyValue(true);
      } else if (event.ctrlKey && event.key === "/") {
        setOpenModal(true);
        setCmdKeyValue(false);
      } else if (event.key === "/") {
        event.preventDefault();
        setFocusOnInput(!focusOnInput);
      }
    },
    [focusOnInput]
  );

  useEffect(() => {
    window.addEventListener("keydown", checkKeyPress);

    return () => {
      window.removeEventListener("keydown", checkKeyPress);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkKeyPress]);

  return (
    <>
      <Joyride
        steps={steps}
        run={show}
        continuous
        scrollToFirstStep
        showProgress
        showSkipButton
        locale={{
          skip: "Pomiń",
          last: "Zakończ",
          next: "Dalej",
          back: "Wstecz",
        }}
        callback={(data) => {
          if (data.action === "reset") {
            setShow(false);
            updateUserInstructionStatus();
          }
        }}
        styles={{
          options: {
            primaryColor: colors.primary,
            zIndex: 10000,
          },
          tooltipContent: {
            whiteSpace: "pre-wrap", // or 'pre-line'
          },
        }}
      />
      <DashboardTemplate
        leftPanel={
          <ChatFeed
            onSourecsChange={(values) => {
              setSources(values);
            }}
            onSourcesLoading={(v) => setSourcesLoading(v)}
            focusOnInput={focusOnInput}
            chatId={id ?? undefined}
          />
        }
        rightPanel={
          <div className="rightSidePanel">
            {sourcesLoading ? (
              <Box
                width={"100%"}
                height={"100vh"}
                padding={"100px"}
                display={"flex"}
                sx={{ backgroundColor: colors.grayBG }}
                justifyContent={"center"}
              >
                <Box width={"200px"}>
                  <Box display={"flex"} justifyContent={"center"}>
                    <CircularProgress size={"30px"} />
                  </Box>
                  <Typography
                    fontStyle={"italic"}
                    color={"darkgray"}
                    width={"100%"}
                    textAlign={"center"}
                  >
                    Wyszukiwanie artykułów
                  </Typography>
                </Box>
              </Box>
            ) : (
              <RightSideBar sources={sources} />
            )}
          </div>
        }
      />
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...modalStyle, width: 600 }}>
          <ShortcutPanel
            cmdKeyUsed={cmdKeyValue}
            onClose={() => setOpenModal(focusOnInput)}
          />
        </Box>
      </Modal>
    </>
  );
};

export default DashboardPage;
