import { Box, Typography } from "@mui/material";
import { LawModel } from "../../../../../../models/LawModel";
import { RulingModel } from "../../../../../../models/RulingModel";
import Source from "./Source";

type Props = {
  sources: LawModel[];
  onRulingSelect: (source: RulingModel) => void;
  onLawSelect: (source: LawModel) => void;
};

const ListOfSources = ({ sources, onRulingSelect, onLawSelect }: Props) => {
  return (
    <>
      {sources.length > 0 ? (
        <>
          {sources.map((source, sourIndex) => (
            <>
              <Source
                source={source}
                onRulingSelect={onRulingSelect}
                onLawSelect={onLawSelect}
              />
            </>
          ))}
        </>
      ) : (
        <Box>
          <Box display={"flex"} justifyContent={"center"}>
            <Typography margin={"40px"} color={"dimgray"}>
              {" "}
              W tym miejscu znajdą się źródła związane z bieżącą konwersacją.
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ListOfSources;
