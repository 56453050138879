import React, { useCallback, useState, createContext } from "react";
import { CaseModel } from "../models/CaseModel";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../configs/firebaseConfig";

async function updateConversationField(conversationId: string, newValue: any[]) {
  const conversationRef = doc(firestore, "Conversations", conversationId);
  newValue.forEach(element => {
    if(element.highlightedArticle == null){
      element.highlightedArticle = "";
    }
    if(element.highlightedParagraph == null){
      element.highlightedParagraph = "";
    }
  });
  await updateDoc(conversationRef, {
    "tabs": newValue
  });
}

interface PropsBasicDataProvider {
  children: React.ReactNode;
}

interface ContextDataModel {
  rulings: CaseModel[];
  addRuling: (ruling: CaseModel, id: string | undefined) => void;
  setRulingList: (rulings: CaseModel[]) => void;
  removeRuling: (ruling: CaseModel, id: string | undefined) => void;
  displayed: CaseModel | "sources" | "search";
  setDisplayedCase: (ruling: CaseModel | "sources" | "search") => void;
  modifyRuling: (ruling: CaseModel, id: string | undefined) => void;
}

export const TabsContext: React.Context<ContextDataModel> = createContext({
  rulings: [] as CaseModel[],
  addRuling: (error: any, id: string | undefined) => { },
  setRulingList: (error: any) => { },
  removeRuling: (error: any, id: string | undefined) => { },
  displayed: "search" as CaseModel | "sources" | "search",
  setDisplayedCase: (error: any) => { },
  modifyRuling: (error: any, id: string | undefined) => { }
});

export const TabsProvider: any = ({ children }: PropsBasicDataProvider) => {
  const [localRulings, setLocalRulings] = useState<CaseModel[]>([]);
  const [localDisplayed, setLocalDisplayed] = useState<CaseModel | "sources" | "search">("search");

  const addRuling = (ruling: CaseModel, id: string | undefined) => {
    setLocalRulings((prevRulings: CaseModel[]) => {
      if (prevRulings.some((r: CaseModel) => r.url === ruling.url)) {
        return prevRulings;
      }
      else {
        if (id) {
          updateConversationField(id, [...prevRulings, ruling]);
        }
        return [...prevRulings, ruling];
      }
    });
  };


  const modifyRuling = (ruling: CaseModel, id: string | undefined) => {
    setLocalRulings((rulingsList: CaseModel[]) => {
      let newList = rulingsList.map((current: any) => {
        if (current.url === ruling.url) {
          return ruling;
        }
        return current;
      });
      if (id) {
        updateConversationField(id, [...newList]);
      }
      return [...newList];
    });
  }

  const setRulingList = (rulings: CaseModel[]) => {
    setLocalRulings(rulings);
  };

  const removeRuling = (ruling: any, id: string | undefined) => {
    setLocalRulings((rulingsList: CaseModel[]) => {
      let newList = rulingsList.filter((current: any) => current.url !== ruling.url);
      if (id) {
        updateConversationField(id, [...newList]);
      }
      return [...newList];
    });
  };

  const setDisplayedCase = (r: CaseModel | "sources" | "search") => {
    setLocalDisplayed(r);
    return r
  }

  const contextValue = {
    rulings: localRulings,
    addRuling: useCallback((error: any, id: string | undefined) => addRuling(error, id), []),
    setRulingList: useCallback((error: any) => setRulingList(error), []),
    removeRuling: useCallback((error: any, id: string | undefined) => removeRuling(error, id), []),
    displayed: localDisplayed,
    setDisplayedCase: useCallback((error: any) => setDisplayedCase(error), []),
    modifyRuling: useCallback((error: any, id: string | undefined) => modifyRuling(error, id), [])
  };

  return (
    <TabsContext.Provider value={contextValue}>
      {children}
    </TabsContext.Provider>
  );
};
