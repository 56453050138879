import React, { useCallback, useState, createContext } from "react";


interface PropsBasicDataProvider {
  children: React.ReactNode;
}

interface ContextDataModel {
  showInstruction: boolean;
  setShowInstruction: (step: boolean) => void;
}

export const InstructionContext: React.Context<ContextDataModel> = createContext({
  showInstruction: false as boolean,
  setShowInstruction: (step: boolean) => {},
});

export const InstructionProvider: any = ({ children }: PropsBasicDataProvider) => {
  const [localStep, setLocalStep] = useState<boolean>(false);

  const setStep = (step:boolean) => {
    setLocalStep(step)
  };


  const contextValue = {
    showInstruction: localStep,
    setShowInstruction: useCallback((step:boolean) => setStep(step), []),
  };

  return (
    <InstructionContext.Provider value={contextValue}>
      {children}
    </InstructionContext.Provider>
  );
};
