import { Box, Button, Grid, Popover, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import colors from "../../../configs/colorConfig";
import { useState } from "react";
import { signOut } from "firebase/auth";
import { auth } from "../../../configs/firebaseConfig";
import { useNavigate } from "react-router";
import React from "react";

type Props = {
  extended: boolean;
};
const LoginControls = ({ extended }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        // An error happened.
      });
  };

  const handleSettings = () => {
    navigate("/ustawienia");
  };

  const handleSubscription = () => {
    navigate("/subskrypcja");
  };

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ width: "200px" }}>
          {/* <Button fullWidth onClick={() => { navigate("/subscription"); setAnchorEl(null) }}>Subskrypcje</Button> */}
          {/* <Button fullWidth onClick={handleSubscription}>
            Konto
          </Button>
          <Button fullWidth onClick={handleSettings}>
            Opcje
          </Button> */}
          <Button fullWidth onClick={handleSignOut}>
            Wyloguj
          </Button>
        </Box>
      </Popover>
      <Grid
        display="flex"
        component={"button"}
        sx={{
          background: colors.sidebarLeft.bg,
          border: "none",
          ":hover": {
            background: "#132034",
          },
        }}
        onClick={handleClick}
      >
        <AccountCircleIcon sx={{ color: colors.sidebarLeft.text }} />
        {extended ? (
          <Typography ml={1} color={colors.sidebarLeft.text}>
            {auth.currentUser?.displayName ?? ""}
          </Typography>
        ) : null}
      </Grid>
    </>
  );
};

export default LoginControls;
