const colors = {
    error: "#b22222",
    highlightedText: '#D6EBF2',
    background: "#fafafa",
    sidebarLeft: {
        bg: "#060019",
        text: "#f0f0f0",
    },
    sidebarRight: {
        bg: "",
        text: "#373737",
    },
    topbar: {
        bg: "#233044",
        color: "#000",
        textColor: "#f0f0f0",
        hoverBg: "#10202a",
    },
    chat: {
        userTextColor: "#070707",
        botTextColor: "#070707",
    },
    mainDetails: "#46AA46",
    mainPrimary: "#344955",
    mainBg: "#f5f5f5",
    mainBg2: "#cd3a21",
    lightGray: "#707070",
    primary: "#915AC2",
    primaryHover: "gray",
    //primary: "#E23715",
    grayBG: "#F3F4F5"
}

export default colors