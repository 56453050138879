import { Box, IconButton } from "@mui/material";
import React from "react";
import assets from "../../../../../../../../assets";
import { PathModel } from "../../LawPanel";

export interface LetterModel {
  name: string;
  text: string;
  highlighted?: boolean;
  numberOfRulings: number;
}

type Props = {
  letter: LetterModel;
  onClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    rulingsNumber: number,
    path: PathModel
  ) => void;
  pointId: string;
};

const LetterView = ({ letter, onClick, pointId }: Props) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    let model: PathModel = {
      article: "",
      paragraph: "",
      point: "",
      letter: letter.name,
    };

    onClick(event, letter.numberOfRulings, model);
  };

  return (
    <div style={{ marginLeft: "40px", display: "flex", alignItems: "start" }}>
      {letter.name !== "" ? (
        <>
          {letter.numberOfRulings > 0 ? (
            <IconButton
              id={pointId + letter.name}
              onClick={handleClick}
              sx={{ padding: "6px" }}
            >
              <Box
                borderRadius={"5px"}
                component={"img"}
                src={assets.icons.hammerBlack}
                width="15px"
                height={"15px"}
              />
            </IconButton>
          ) : (
            <IconButton disabled={true} sx={{ padding: "6px" }}>
              <Box width="15px" height={"15px"} />
            </IconButton>
          )}
          <div>
            <div
              style={{ paddingTop: "4px", paddingBottom: "4px" }}
              dangerouslySetInnerHTML={{ __html: letter.text }}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default LetterView;
