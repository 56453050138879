import { Box, useMediaQuery, useTheme } from "@mui/material";
import SplitPane, {
  SplitPaneLeft,
  SplitPaneRight,
  Divider,
} from "../../molecules/split-pane/SplitPane";
import SwitchButtons from "../../atoms/switch-buttons/SwitchButtons";
import { useState } from "react";

type Props = {
  leftPanel: React.ReactNode;
  rightPanel: React.ReactNode;
};

const DashboardTemplate = ({ leftPanel, rightPanel }: Props) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [shownSide, setShownSide] = useState<"one" | "two">("one");

  return (
    <>
      <Box sx={{ height: "100vh" }} width={"100%"} display={"flex"}>
        {!isSmallScreen ? (
          <SplitPane>
            <SplitPaneLeft>{leftPanel}</SplitPaneLeft>
            <Divider />
            <SplitPaneRight>{rightPanel}</SplitPaneRight>
          </SplitPane>
        ) : (
          <Box width={"100%"} height={"100%"}>
            <SwitchButtons
              margin={"10px"}
              titleOne={"Czat"}
              titleTwo={"Źródła"}
              onChange={(side) => {
                setShownSide(side);
              }}
              value={shownSide}
            />
            {shownSide === "one" ? leftPanel : rightPanel}
          </Box>
        )}
      </Box>
    </>
  );
};

export default DashboardTemplate;
